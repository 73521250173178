import React from "react";
import {
  BottomContainer,
  Container,
  Header,
  SubHeader,
  Thousands,
  TopContainer,
  TopLeft,
  TopRight,
} from "./styles";
import useWindowSize, { Size } from "../../utilities/Hooks/useWindowSize";
import BottomRow from "./BottomRow";
import { Link, useNavigate } from "react-router-dom";
import { img } from "../../assets/imageFolder";
import Button from "../../components/Button";

const OwedThousands = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  return (
    <Container>
      <TopContainer>
        <TopLeft>
          <Header>
            You Could Be {width! < 1350 && <br />}
            <span>
              Owed Thousands
              <Thousands src={img.thousands} />
            </span>
          </Header>
          {/* {size.width! > 1400 || (size.width! < 1000 && size.width! > 700) ? (
            <Thousands src={img.thousands} />
          ) : (
            ""
          )} */}
          <p>
            Business owners like you often rely on an energy broker or third
            party helper to help them find the best deal on their business
            energy, with these brokers earning commission on the contracts they
            sell to you.
          </p>
          <p>
            If your business has used an energy broker, they may have sold you
            an energy deal with hidden, added commission that goes into their
            back pocket.
          </p>
          <p>
            Business energy bills for most companies in the UK are high right
            now, and one reason could be due to these hidden commissions. If you
            were not told about these commissions by your broker, you could have
            been mis-sold. Remember: it costs you nothing to check below.
          </p>
        </TopLeft>
        <TopRight>
          <SubHeader>
            Let’s help you <strong>claim back a refund </strong>from your
            <strong> business energy contract</strong>. If it’s not successful,
            <strong> you pay us nothing</strong>.
          </SubHeader>

          <Button
            btnText="CHECK FOR FREE"
            btnImg
            onClick={() => navigate("/form")}
          />
        </TopRight>
      </TopContainer>
      <BottomContainer>
        <BottomRow />
      </BottomContainer>
    </Container>
  );
};

export default OwedThousands;
