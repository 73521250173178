import Search from "../../search/Index";
import { Container, DropdownContainer, Head, InnerContainer } from "./styles";
import React, { useEffect, useState } from "react";

const QuestionOne = () => {
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [showObj, setShowObj] = useState({
    popup: false,
    businesses: false,
    submit: false,
    search: true,
  });
  const [exit, setExit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <Head>What is the name of your Limited Company?</Head>
        <DropdownContainer>
          <Search
            selectedBusiness={selectedBusiness}
            setSelectedBusiness={setSelectedBusiness}
            showObj={showObj}
            setShowObj={setShowObj}
          />
        </DropdownContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionOne;
