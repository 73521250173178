import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 102px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
`;

export const Input = styled.input`
  width: 409px;
  height: 52px;
  border-radius: 1px;
  margin-right: 17px;
  font-family: "Inter", sans-serif;
  color: white;
  font-size: 20px;
  line-height: 140%;
  padding-left: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  &::placeholder {
    color: white;
  }
  @media (max-width: 500px) {
    width: 327px;
    height: 46px;
    margin-right: 0px;
    margin-bottom: 22px;
  }

  @media (max-width: 570px) {
    margin-bottom: 22px;
  }
  @media (min-height: 1080px) and (min-width: 1050px) {
    width: 500px;
    height: 60px;

    font-size: 35px;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 56px;

  padding: 14px 39px 14px 28px;
  background: rgba(0, 166, 245, 1);
  border: 1px solid #ffffff;
  cursor: pointer;
  /* margin-top: 1px; */
  @media (max-width: 500px) {
    width: 132px;
    height: 46px;

    padding: 0;
  }

  @media (min-height: 1080px) and (min-width: 1050px) {
    width: 250px;
    height: 60px;

    font-size: 35px;
  }

  :hover {
    background: #1e2241;
  }
`;

export const SearchImg = styled.img`
  margin-right: 8px;
  @media (min-height: 1080px) {
    width: 15%;
  }
`;

export const SearchText = styled.p`
  color: rgba(255, 255, 255, 1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;

  @media (min-height: 1050px) and (min-width: 1050px) {
    font-size: 20px;
  }
`;

export interface iSCOpen {
  open: boolean;
}

export const BusinessListContainer = styled.div<iSCOpen>`
  width: 409px;
  padding-right: 20px;

  height: ${(props: iSCOpen) => (props.open ? `135px;` : "0")};
  border: ${(props: iSCOpen) => (props.open ? ` 1px solid #ffffff;;` : "none")};
  z-index: ${(props: iSCOpen) => (props.open ? ` 1;` : "")};
  opacity: ${(props: iSCOpen) => (props.open ? ` 1;` : "0")};

  pointer-events: ${(props: iSCOpen) => !props.open && "none"};
  font-family: "Inter", sans-serif;
  color: rgba(20, 6, 79, 1);
  background: ${(props: iSCOpen) => (props.open ? ` rgba(255, 255, 255, 0.1);` : "")};

  font-size: 16px;
  font-weight: 300;

  position: absolute;
  overflow: scroll;
  top: 55px;

  z-index: 2;
  @media (max-width: 500px) {
    margin-left: 0px;
    top: 45px;
    width: 327px;
    height: ${(props: iSCOpen) => (props.open ? `100px;` : "0")};

    font-size: 12px;
  }

  @media (min-height: 1080px) and (min-width: 1050px) {
    width: 500px;
  }

  @media (min-height: 1080px) {
    top: 60px;
    height: 200px;
    font-size: 30px;
  }
`;

export const BusinessList = styled.ul`
  list-style: none;
  margin-top: 0px;
  padding-left: 0px;
  background: #1f3f73;
`;

export const Business = styled.li`
  padding-top: 11px;
  padding-left: 20px;
  padding-bottom: 11px;
  border-bottom: 0.1px solid white;
  color: white;
  width: 100%;
  :hover {
    border: 1px solid black;
    cursor: pointer;
  }
`;

export const SearchCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  width: fit-content;
  background: rgba(238, 101, 2, 1);
  border: 1px solid #ffffff;
  box-shadow: 6px 2px 14px rgba(0, 0, 0, 0.2);
  border-radius: 56px;
  color: #ffffff;
  font-size: 26px;
  font-weight: 400;
  padding: 16px 56px;
  position: absolute;
  @media (max-width: 500px) {
    width: 155px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 114px;
  }

  @media (min-height: 1080px) {
    width: 500px;
    height: 80px;
    font-size: 35px;
    margin-top: 20px;
  }

  :hover {
    cursor: pointer;
    background: #c25708;
  }
`;
