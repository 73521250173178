import styled from "styled-components";
import heroBackground from "../../assets/imageFolder/heroBackground.png";
import { CenteredRowMixin, ColumnMixin } from "../../theme/reuseable";

export const Section = styled.section`
  background-image: url(${heroBackground});
  background-size: 100% 100%;
  width: 100vw;
  padding-bottom: 67px;

  @media (max-width: 768px) {
    background-image: unset;
    background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);
  }
`;

export const InnerHero = styled.div`
  max-width: 1300px;
  padding: 22px 80px 25px;
  ${CenteredRowMixin};
  margin-inline: auto;
  padding-right: 108px;
  gap: 5%;
  margin-top: 48px;
  font-family: "SF Pro Display";

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding-inline: 24px;
    margin-top: unset;
    align-items: flex-start;

    h1 {
      padding-top: 0;
    }
  }
`;

export const TicksContainer = styled.div`
  ${ColumnMixin};
  gap: 21px;
  margin-bottom: 25px;

  @media (max-width: 500px) {
    margin-bottom: 32px;
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    margin: auto;
    width: fit-content;
    margin-top: 25px;
  }
`;

export const Heading = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  color: #ffffff;
  width: 579px;
  margin-top: 0;
  margin-bottom: 25px;

  span {
    color: #269cd4;
  }
  @media (max-width: 1200px) {
    width: fit-content;
  }

  @media (max-width: 580px) {
    font-size: 28px;
    line-height: 38px;
    width: 327px;
    padding-top: 25px;
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    margin: auto;
    margin-top: 25px;
  }
  @media (max-width: 1300px) and (min-width: 1000px) {
    width: 450px;
  }
  @media (min-width: 1700px) {
    font-size: 60px;
  }
`;

export const SubHeader = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 19px;
  }
`;
