import { ActionCreator, Dispatch } from "redux";
import {
  finalObj,
  UPDATING_FINAL,
  UpdatingFinalT,
} from "../types/UpdateFinalObj.types";

export const FinalUpdateAction: ActionCreator<UpdatingFinalT> = (
  payload: finalObj
) => {
  return { type: UPDATING_FINAL, payload: payload };
};

export const UpdateFinalObj = (payload: any, key1: any, key2: any): any => {
  return async (dispatch: Dispatch, getState: any) => {
    let formObj = getState()?.UpdateFinalObjReducer;

    const updatedDetails = {
      ...formObj,
      [key1]: { ...formObj[key1], [key2]: payload },
    };

    dispatch(FinalUpdateAction(updatedDetails));
  };
};

export const updateAddress = (address: string): any => {
  return async (dispatch: Dispatch, getState: any) => {
    let formObj = getState()?.UpdateFinalObjReducer;

    const splitAddress = address.split(",");

    let addressObj = {
      addressLineOne: splitAddress[0],
      addressLineTwo: splitAddress[1],
      addressLineThree: splitAddress[2],
      city: splitAddress.slice(3, 4).toString(),
      postcode: splitAddress.pop()?.toString(),
    };

    const updatedDetails = {
      ...formObj,
      address: addressObj,
    };

    dispatch(FinalUpdateAction(updatedDetails));
  };
};
