import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { UpdateFinalObj } from "../../../redux/actions/UpdateFinalObj.actions";
import {
  Container,
  DetailsContainer,
  Head,
  InnerContainer,
  Input,
  Label,
  LabelCont,
  Next,
  NextCont,
  TestContainer,
} from "./styles";

const QuestionFive = () => {
  const [email, setEmail] = useState("");
  const [bad, setBad] = useState(false);
  const [exit, setExit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onClickNext = async () => {
    Loading.standard("Loading...");

    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      Loading.remove();
      setBad(false);
      dispatch(UpdateFinalObj(email, "lead", "email"));
      dispatch(UpdateShowingQuestion(5));
    } else {
      Loading.remove();
      Notify.failure("Please make sure email is correct");
    }
  };

  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <TestContainer>
          <Head>Please enter your email address</Head>
          <DetailsContainer>
            <LabelCont>
              <Label>Your Email Address</Label>
              <Input
                type="text"
                onChange={onChange}
                className={bad ? "bad" : "default"}
              ></Input>
            </LabelCont>
          </DetailsContainer>

          <NextCont>
            <Next onClick={onClickNext}>NEXT</Next>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionFive;
