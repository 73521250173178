import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 28px;
  max-width: 1300px;
  @media (min-width: 1650px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 28px;
  }
`;

export const Tab = styled.div`
  width: 373px;
  max-width: 80vw;
  margin-top: 43px;

  img {
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    width: 327px;
    max-width: 93vw;
  }
`;

export const Header = styled.p`
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 24px;
  }
`;
