import styled from "styled-components";
import { CenteredRowMixin } from "../../theme/reuseable";

export const TickWrapper = styled.div`
  ${CenteredRowMixin};
  gap: 8px;
  position: relative;

  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    margin: 0;

    strong {
      font-weight: 700;
      color: #ffffff;
    }
  }

  @media (max-width: 500px) {
    h6 {
      font-size: 14px;
      line-height: 24px;
    }
    img {
      margin-right: 8px;
      margin-right: 16px;
    }
  }
`;

export const Line = styled.img`
  position: absolute;
  top: 21px;
  left: 30px;
  @media (max-width: 500px) {
    bottom: 25px;
    width: 50px;
    left: 41px;
  }
`;
