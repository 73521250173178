import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
// import Form from "./pages/Form";
import Landing from "./pages/Landing";
// import ThankYou from "./pages/ThankYou";
import { store } from "./redux/store";
// import ReactPixel from "react-facebook-pixel";
// import Sorry from "./pages/Sorry";
import { rootReducer, RootState } from "./redux/reducers";
import { GlobalStyle } from "./theme/globalStyles";
import { buildTracking } from "./utilities/helpers";
import Form from "pages/Form";
import ThankYou from "pages/ThankYou";
import Sorry from "pages/Sorry";

declare global {
  interface Window {
    _mfq: any[];
    dataLayer: any[];
  }
}

function App() {
  // const [hasMounted, setHasMounted] = useState<boolean>(false);

  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: true, // enable logs
  // };

  // useEffect(() => {
  //   setHasMounted(true);
  // }, []);

  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === "production") {
  //     console.log("Prod Tracking");

  //     buildTracking(
  //       "//cdn.mouseflow.com/projects/e4d017e9-91fe-4562-86f1-73f3b51bd6bf.js"
  //     );
  //     ReactPixel.init("1301319807291138", undefined, options);
  //     ReactPixel.pageView();
  //   }
  // }, [hasMounted]);
  const form = useSelector(
    (rootReducer: RootState) => rootReducer?.UpdateLeadReducer
  );

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/form" element={<Form reengage={false} />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/sorry" element={<Sorry />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
