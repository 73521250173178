import { iFaq } from "./types";

const faqArr: iFaq[] = [
  {
    header: "Can’t I just ask my broker about their commission earnings?",
    paras: [
      "You can freely ask your broker about the commission, but as the energy broker industry is currently unregulated, they have no obligation to openly declare just how much money they are earning off commissions attached to the deal they sold you.",
      "To find out if you were potentially mis-sold by your broker, <a href='/form'> start your free check here.</a>.",
    ],
    lastPara: [],
  },
  {
    header: "Will making a business energy claim cost me anything?",
    paras: [
      "All our services are no-win, no-fee. This means that unless you get a successful payout, you won’t owe a single penny for your claim.",
      "Begin your claim for compensation now by <a href='/form'> starting our free online application now.</q>",
    ],
    lastPara: [],
  },
  {
    header: "Will making a claim badly affect my current deal?",
    paras: [
      "In the past, we’ve seen brokers and energy companies offer revised, improved deals to customers once evidence of mis-selling has been brought to light.",
      "Find out if you’re eligible to claim today by <a href='/form'> completing our online form now.</q>",
    ],
    lastPara: [],
  },
  {
    header: "Which companies may have mis-sold me my business energy deal?",
    paras: [
      "Refund My Business Energy looks at claims of mis-selling for ALL energy companies, regardless of how big or small they are. These include the traditional ‘Big Six’ energy companies, including British Gas, ScottishPower and EDF Energy, as well as ‘newer’ energy companies like Octopus Energy and OVO Energy.",
      "Start your claim for compensation today by <a href='/form'> filling out our free online form now.</q>",
    ],
    lastPara: [],
  },
  {
    header: "How do I know if I was mis-sold my business energy deal?",
    paras: [
      "There are many red flags and warning signs to look out for in regards to what a mis-sold business energy deal looks like.  We’ve highlighted the following features to look out for in a mis-sold business energy deal:",
    ],
    list: [
      "You’re paying unusually high amounts every month.",
      "Your broker and energy provider assured you everything is fine and normal.",
      "Your business is experiencing heavy financial losses that you can link to increased business energy costs.",
    ],
    lastPara: [
      "If you believe you’ve been mis-sold, find out if <a href='/form'> you could be compensated here.</q>",
    ],
  },
  {
    header:
      "How long until I can expect a payout for my mis-sold business energy claim?",
    paras: [
      "If we believe you could make a claim for compensation, we look to have your case resolved and your financial payout delivered to you within 12 weeks.",
      "Get started on your claim for free today by <a href='/form'> accessing our online form here.</q>",
    ],
    lastPara: [],
  },
  {
    header:
      "What is a broker, and how may they have mis-sold me my business energy deal?",
    paras: [
      "Your energy broker, sometimes referred to as a ‘Third Party Intermediary’ or TPI, acts as a middleman between businesses like yours and energy companies. It’s their job to find the best business energy deal for you. But recent findings show they may be adding extra, undisclosed commissions to your packages for their own financial gain.",
      "If you believe a broker has mis-sold you your deal, <a href='/form'> check for compensation now.</q>",
    ],
    lastPara: [],
  },
  {
    header: "How much could I save for my business with a claim?",
    paras: [
      "We’ve seen businesses claim back up to tens of thousands of pounds in compensation for being mis-sold their business energy deal. And with millions of UK companies eligible to make a claim, there’s an excellent chance you could be able to launch a case for compensation with us.",
      "Find out if your business is eligible to  <a href='/form'> claim back today by clicking here.</q>",
    ],
    lastPara: [],
  },
  {
    header: "What do I need in order to make a claim for mis-selling?",
    paras: [
      "In order to start a claim for mis-sold business energy, here’s a brief list of things you’ll need in order to help us process your claim.",
    ],
    list: [
      "The name of your company.",
      "Your company’s limited status.",
      "The name of your business energy provider.",
    ],
    lastPara: [
      "You can get started on your application today by <a href='/form'> clicking the link here.</q>",
    ],
  },
  {
    header:
      "What is mis-sold business energy and how could it affect me and my company?",
    paras: [
      "Mis-sold business energy claims are designed to see if you’re paying too much money in hidden commissions on your business energy deal. These hidden commissions are affecting the operation of businesses up and down the country, with recent OFGEM data showing that some energy deals are sold with a 50% commission for the broker.",
      "Find out if you and your business could be due compensation by <a href='/form'> filling out our online form today.</q>",
    ],
    lastPara: [],
  },
];

export default faqArr;
