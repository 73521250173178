import styled, { css, keyframes } from "styled-components";
import { CenteredRowMixin, rotate } from "../../theme/reuseable";
import { theme } from "../../theme/theme";

export const buttonMixin = `

    border: 1px solid #ffffff;
    box-shadow: 6px 2px 14px rgba(0, 0, 0, 0.2);
    border-radius: 56px;
    color: ${theme?.colors?.white};
    
    :hover{ 
        cursor:pointer;
    }
`;

export const OrangeButton = styled.button`
  width: 375px;
  height: 72px;
  ${buttonMixin}
  background: ${theme?.colors?.orangeButton};

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-left: 40px;
  padding-right: 16px;

  font-size: 26px;
  font-weight: 600;

  line-height: 31px;

  gap: 20px;

  &:hover {
    background: #c25708;
  }

  @media (min-width: 1024px) {
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    width: 241px;
    height: 54px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding-left: 24px;
    white-space: nowrap;
    padding-right: 15px;

    img {
      height: 30px;
      width: 30px;
    }
  }
`;

export const NextButton = styled.button`
  width: 182px;
  height: 63px;
  ${buttonMixin}
  background: ${theme?.colors?.orangeButton};
  color: ${theme?.colors?.white};
  justify-content: space-between;
  padding-inline: 40px;

  :hover {
    background: "#C25708;";
  }

  @media (min-width: 1024px) {
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FormButton = styled(OrangeButton)`
  @media (min-width: 1024px) {
    width: 228px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgb(7, 163, 88, 0.5); // Theme -> Color -> Green
  border-radius: 50%;
  border-top-color: ${theme?.colors?.orangeButton};
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear infinite;
  transition-delay: "200ms";
`;
