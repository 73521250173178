import bg from "./bg.svg";
import sse from "./sse.svg";
import crown from "./crown.svg";
import eon from "./eon.svg";
import ovo from "./ovo.svg";
import scot from "./scot.svg";
import opus from "./opus.svg";
import drax from "./drax.svg";
import edf from "./edf.svg";
import octopusEnergy from "./octopusEnergy.svg";
import nPower from "./nPower.svg";
import pozitiveEnergy from "./pozitiveEnergy.svg";
import total from "./total.svg";
import gazprom from "./gazprom.svg";
import engie from "./engie.svg";
import smartestEnergy from "./smartestEnergy.svg";
import coronaEnergy from "./coronaEnergy.svg";

export interface Types {
  [key: string]: string;
}

const provider: Types = {
  bg,
  sse,
  crown,
  eon,
  ovo,
  scot,
  opus,
  drax,
  edf,
  octopusEnergy,
  nPower,
  pozitiveEnergy,
  total,
  gazprom,
  engie,
  smartestEnergy,
  coronaEnergy,
};

export { provider };
