import {
  finalObj,
  UPDATING_FINAL,
  UpdatingFinalT,
} from "../types/UpdateFinalObj.types";

export const initialFinalObj: finalObj = {
  lead: {
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
  },
  company: {
    companyNumber: 0,
    officer: "",
    lastFiling: "",
    companyStatus: "",
    companyType: "",
    dateOfCreation: "",
    description: "",
    infoDump: {},
    monthlyBill: 0,
  },
  address: {
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    city: "",
    postcode: "",
  },
};

export const UpdateFinalObjReducer = (
  state: finalObj = initialFinalObj,
  action: UpdatingFinalT
): finalObj => {
  switch (action.type) {
    case UPDATING_FINAL: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
