import { Container, FaqsContainer, Header } from "./styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import faqArr from "./data";
import { img } from "../../assets/imageFolder";
import { iFaq } from "./types";
import Faq from "../../components/Faq";

const Faqs = () => {
  const [openHeader, setOpenHeader]: any = useState<string>(
    "Can’t I just ask my broker about their commission earnings?"
  );

  const navigate = useNavigate();

  const clickLink = () => {
    navigate("/form");
  };

  const faqs = faqArr.map((faq: iFaq) => {
    return (
      <Faq
        key={faq.header}
        faq={faq}
        open={openHeader === faq.header}
        setOpenHeader={setOpenHeader}
      />
    );
  });
  return (
    <Container>
      <Header>FAQs</Header>
      <FaqsContainer>{faqs}</FaqsContainer>
    </Container>
  );
};

export default Faqs;
