import styled, { keyframes } from "styled-components";

const glideIn = keyframes`
  0%{
    opacity:0%
  }
  100%{
    opacity:100%;
  transform: translateY(0%);

  }
`;

export const Container = styled.div`
  background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);
  min-height: 100vh;

  @media (min-width: 1900px) {
    padding-top: 5%;
    padding-bottom: 17%;
  }
`;

export const TestContainer = styled.div`
  animation: ${glideIn} 0.3s linear 0s 1 forwards;
  opacity: 0%;
  transform: translateY(30%);
`;

export const InnerContainer = styled.div``;

export const Head = styled.h1`
  text-align: center;
  padding-top: 74px;
  font-weight: 700;
  font-size: 38px;
  color: white;
  margin-bottom: 48px;
  max-width: 774px;
  margin: auto;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
    width: 327px;
    margin: auto;
    padding-top: 38px;
    padding-bottom: 00px;
  }

  @media (max-width: 1000px) and (min-width: 500px) {
    margin: 0px 25px 52px 25px;
  }

  @media (min-height: 1080px) {
    font-size: 40px;
    max-width: 774px;
    margin: auto;
    margin-bottom: 62px;
  }
`;

export const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ffffff;
    max-width: 661px;
  }
  .fileDrop,
  .fileInput {
  }

  .fileName {
    color: #00a6f5;
    margin: 20px;
  }
  .mainInput {
    background-color: transparent;
    width: 100%;
    height: 175px;
    border: solid red;
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    /* width: 539px;
    height: 175px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    margin-top: 44px;
    margin-bottom: 22px; */
  }
  .fileUpload {
    background: #00a6f5;
    border: 1px solid #ffffff;
    padding: 14px 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
  }

  .fileInput {
  }
`;

export const LabelCont = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  position: relative;
  @media (max-width: 500px) {
  }
`;
export interface iInputContainer {
  file: boolean;
}
export const InputContainer = styled.div<iInputContainer>`
  ${(props: iInputContainer) =>
    props.file
      ? `display: none; height: 0;`
      : `
  
  width: 539px;
  height: 175px;
   border: 1px solid #ffffff; 

  `}

  background: rgba(255, 255, 255, 0.1);
  display: grid;
  place-items: center;
  margin-top: 44px;
  margin-bottom: 22px;

  position: relative;
  .inputText {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (min-height: 700px) {
    ${(props: iInputContainer) =>
      props.file
        ? `display: none; height: 0;`
        : `
  
    width: 300px;
    height: 155px;
    order: 1px solid #ffffff; 

  `}
  }
`;

export const Label = styled.label`
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  color: White;
  @media (min-height: 1080px) {
    /* width: 700px; */

    font-size: 20px;
  }
`;

export const Input = styled.input`
  width: 409px;
  height: 52px;
  font-family: "Inter", sans-serif;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  color: white;
  font-size: 16px;
  font-weight: 300;
  padding-left: 20px;
  border-radius: 1px;

  @media (max-width: 500px) {
    width: 327px;
    height: 46px;
  }
  @media (min-height: 1080px) and (min-width: 1050px) {
    width: 500px;
    height: 60px;
    font-size: 35px;
  }
`;

export const Next = styled.button`
  background: #e46f1a;
  border: 1px solid #ffffff;
  box-shadow: 6px 2px 14px rgba(0, 0, 0, 0.2);
  border-radius: 56px;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  padding: 16px 56px;
  margin-top: 40px;
  @media (max-width: 500px) {
    width: 155px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 110px;
    padding-inline: unset;
  }

  @media (min-height: 1080px) {
    /* width: 300px; */
    height: 80px;
    font-size: 35px;
    margin-top: 20px;
  }

  :hover {
    cursor: pointer;
    background: #c25708;
  }
`;

export const NavyNext = styled(Next)`
  background: #1e3f73;

  :hover {
    background: #14315f;
  }
`;
export const NextCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  padding-bottom: 107px;
  gap: 48px;

  @media (max-width: 1000px) and (min-width: 500px) {
    margin-bottom: 325px;
  }
  @media (max-width: 500px) {
    /* margin-bottom: 98px; */
    gap: 32px;
    margin-top: 61px;
  }
`;
