export interface finalObj {
  showing: number;
}

export const UPDATING_SHOWING = "UPDATING_SHOWING";

export interface updateShowing {
  type: typeof UPDATING_SHOWING;
  payload: finalObj;
}
export type UpdatingShowingT = updateShowing;
