import styled from "styled-components";

export const FormBody = styled.div`
  max-width: 1163px;
  margin: auto;
  margin-top: 83px;

  .content {
    display: flex;

    align-items: flex-start;
    justify-content: space-between;
    margin-top: 51px;
    margin-bottom: 140px;
  }

  @media (max-width: 1200px) {
    /* max-width: 850px; */
    .content {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 580px) {
    margin-top: unset;

    .content {
      margin-top: unset;
      background-color: white;
      margin-bottom: unset;
      padding-bottom: 140px;
    }
  }
`;

export const ThankYouWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);

  .inner {
    padding-top: 99px;

    max-width: 715px;
    margin: auto;

    @media (min-width: 1700px) {
      padding-top: 0px;
      margin-top: 130px;

      max-width: 1500px;
    }

    text-align: center;
    h1 {
      width: 100%;
    }
  }

  @media (max-width: 580px) {
    .inner {
      padding-top: 52px;

      padding-bottom: 44px;
    }
  }

  button {
    padding: 16px 56px;
    margin-bottom: 145px;
    @media (max-width: 500px) {
      padding: 16px 53px;

      width: 255px;
      height: 51px;
      font-size: 16px;
      line-height: 19px;
    }
    p {
      margin-right: 0px;
    }
  }
`;

export const ErrorMessage = styled.div`
  background-color: pink;
  border: solid red 2px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  p {
    color: red;
    text-align: center;
  }
`;

export const SubHeading = styled.p`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin: 45px 0;
  @media (max-width: 500px) {
    margin-top: 21px;
    width: 327px;
    font-size: 14px;
  }
  @media (min-width: 1700px) {
    font-size: 30px;
    line-height: 40px;
    width: 900px;
  }
`;

export const ThankYouContent = styled.p`
  letter-spacing: -0.01em;

  color: #ffffff;
  margin: 45px 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  max-width: 661px;
  text-align: center;

  @media (max-width: 800px) {
    max-width: 500px;
  }
  @media (max-width: 500px) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 327px;
  }
`;

export const SorryWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);

  .inner {
    padding-top: 99px;

    max-width: 715px;
    margin: auto;
    text-align: center;
    margin: auto;

    @media (min-width: 1700px) {
      padding-top: 0px;
      margin-top: 130px;

      max-width: 1500px;
    }
    h1 {
      width: 100%;
    }
  }

  @media (max-width: 580px) {
    .inner {
      padding-top: 52px;
      padding-bottom: 44px;
    }
  }

  button {
    padding: 16px 56px;
    margin-bottom: 145px;
    p {
      margin-right: 0px;
    }
  }
`;
