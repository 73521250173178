import { env } from "../../env";

const getBusinessFilingDetails = (number: string) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://api.company-information.service.gov.uk/company/${number}/filing-history`
      );
      xhr.setRequestHeader(
        "Authorization",
        `Basic ${btoa(`${env.REACT_APP_COMPANIES_HOUSE_API_KEY}`)}`
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            const date = response.items[0].date;
            resolve(date);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      xhr.send();
    } catch (e) {
      reject(e);
    }
  });
};

export default getBusinessFilingDetails;
