import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    order: 0;
    margin-top: 31px;
  }
  @media (max-width: 1300px) and (min-width: 1000px) {
    transform: scale(0.8);
    z-index: 3;
  }
`;

export const growAndShrink = keyframes`
  0% {      
     transform: scale(1.0);
    -webkit-transform: scale(1.0);
 }
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1); }

  100% {
  transform: scale(1.0);
    -webkit-transform: scale(1.0); }
  `;

export const Inner = styled.div`
  background: #095b95;
  border: 1px solid #ffffff;
  padding: 20px 32px;
  width: clamp(33%, 531px, 531px);
  min-height: 407px;
  display: flex;
  flex-direction: column;

  > img {
    margin-top: 26px;

    margin: auto;
  }

  button {
    /* transform: scale(0.7); */
    animation: ${growAndShrink} linear 1.4s infinite;
  }
  @media (max-width: 1200px) {
    width: 33vw;

    button {
      max-width: 100%;
      font-size: 16px;
      white-space: nowrap;
      align-items: center;
      padding-left: 20px;
      margin: auto;
    }
  }
  @media (max-width: 899px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100% !important;
    height: 318px;
    margin-left: auto;
    padding: 12px 17px;
    min-height: 0px;
    margin-bottom: 34px;
    > img:last-child {
      margin-top: 20px;
      padding-bottom: 10px;
    }
  }

  @media (min-width: 500px) and (max-width: 1000px) {
    margin-left: 0px;
  }
  @media (max-width: 1300px) and (min-width: 1000px) {
    margin-left: 0px;
  }

  .input-container {
    width: 100%;
    position: relative;

    .dropdown-results {
      margin: 0 !important;
      top: 62%;
      width: 100%;
      height: 180px;
      overflow-y: scroll;
      position: absolute;
      background: white;
      z-index: 10;
      border: 1px solid $secondary_color;
      border-radius: 10px;

      li {
        padding: 1rem;
        font-size: 12px;
        border-bottom: 0.5px solid grey;
        cursor: pointer;
      }
    }
  }
`;

export const SubHeader = styled.p`
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 127.34%;
  color: #ffffff;
  margin: unset;

  @media (max-width: 1200px) {
    font-size: 14px;
    font-weight: 500;

    margin-right: unset !important;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Header = styled(SubHeader)`
  font-weight: 700;
  font-size: 32px;
  width: 327px;
  margin-right: 77px;

  @media (max-width: 500px) {
    font-size: 24px;
    margin-right: 10px;
    width: 242px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 30px;
  @media (max-width: 500px) {
    align-items: flex-start;
    margin-top: 13px;
  }
  img {
    @media (max-width: 1200px) {
      width: 35px;
    }
    @media (max-width: 500px) {
      width: 35px;
    }
  }
`;

export const Postcode = styled.input`
  width: 100%;
  height: 68px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #1e2241;
  margin-bottom: 21px;
  &::placeholder {
    color: #1e2241;
    opacity: 0.5;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    height: 54px;
  }
  @media (max-width: 500px) {
    margin-top: 17px;
    font-size: 16px;
    height: 54px;
  }
`;
