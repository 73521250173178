import React from "react";
import { useNavigate } from "react-router-dom";
import { img } from "../../../assets/imageFolder";
import Button from "../../../components/Button";
import useWindowSize from "../../../utilities/Hooks/useWindowSize";
import { Container, Header, Tab, Text } from "./styles";

const BottomRow = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  return (
    <Container>
      <Tab>
        <img src={img.tab1} />
        <Header>Claim Against The UK’s Biggest Suppliers: </Header>
        <Text>
          If you had a business energy deal with most mainstream energy
          providers, including any of the ‘Big Six’, then you could make a claim
          for compensation today!
        </Text>
      </Tab>
      <Tab>
        <img src={img.tab2} />
        <Header>Expert, Unrivalled Service:</Header>
        <Text>
          Find out if you have a potential claim in a matter of minutes, with
          our specialist team ready to help you fight your case on a no-win,
          no-fee basis.
        </Text>
      </Tab>
      <Tab>
        <img src={img.tab3} />
        <Header>Get Potentially MILLIONS Of Pounds Back</Header>
        <Text>
          Because of the high commissions attached, you could be due major
          financial compensation. Find out if you have a claim today!
        </Text>
      </Tab>
      {width! < 600 ? (
        <Button
          btnText="CHECK FOR FREE"
          onClick={() => navigate("/form")}
          btnImg
        />
      ) : null}
    </Container>
  );
};

export default BottomRow;
