import fiveStar from "./fiveStar.svg";
import footerLogo from "./footerLogo.svg";
import footerSecure from "./footerSecure.svg";
import logo from "./logo.svg";
// import noWinNoFee from "./noWinNoFee.png";
import opopLogo from "./opopLogo.svg";
// import secretCost from "./secretCost.png";
// import sectionTwo from "./sectionTwo.png";
import secure from "./secure.svg";
// import mobileNoWin from "./mobileNoWinNoFee.png";
import accordionArrow from "./accordionArrow.svg";
import info from "./orangeInfo.svg";
import energyBill from "./energyBillExample.svg";
import contract from "./contractExample.svg";
import uploadDocs from "./uploadDocs.svg";
import greenTick from "./greenTick.svg";
import cross from "./cross.svg";
import selectIcon from "./selectIcon.svg";
import modalInfo from "./modalInfo.svg";
import commissionImg from "./commissionImg.svg";
import mobileCommission from "./mobileCommission.svg";
import close from "./close.svg";
import headerOne from "./headerone.svg";
import headerTwo from "./headertwo.svg";
import securelogo from "./securelogo.svg";
import tick from "./tick.svg";
import line from "./line.svg";
import heroIcons from "./heroIcons.svg";
import heroBackground from "./heroBackground.png";

import bottomBtn from "./button.svg";
import arrow from "./arrow.svg";
import smallIcons from "./smallIcons.svg";
import ombud from "./ombud.svg";
import ofgem from "./ofgem.svg";
import mse from "./mse.svg";
import tim from "./tim.svg";
import vector from "./vector.svg";
import faqArrow from "./faqArrow.svg";
import dev from "./dev.svg";
import securefooter from "./securefooter.svg";
import tick2 from "./tick2.svg";
import blackline from "./blackline.svg";

import thousands from "./thousands.svg";
import tab1 from "./tab1.svg";
import tab2 from "./tab2.svg";
import tab3 from "./tab3.svg";

import person1 from "./person1.svg";
import person2 from "./person2.svg";
import person3 from "./person3.svg";

import refundMyBusinessLogo from "./refundMyBusinessLogo.svg";
import whiteLogo from "./whiteLogo.svg";

import magnifyingGlass from "./magnifyingGlass.svg";
import lightBlueArrow from "./lightBlueArrow.svg";

import faqDownArrow from "./faqDownArrow.svg";
import thisIsMoney from "./thisIsMoney.svg";

import greenTickCircle from "./greenTickCircle.svg";

import eSignature from "./eSignature.png";

export interface Types {
  [key: string]: string;
}

const img: Types = {
  fiveStar,
  footerLogo,
  footerSecure,
  logo,
  // noWinNoFee,
  opopLogo,
  // secretCost,
  // sectionTwo,
  secure,
  arrow,
  // mobileNoWin,
  accordionArrow,
  info,
  energyBill,
  contract,
  uploadDocs,
  greenTick,
  cross,
  selectIcon,
  modalInfo,
  commissionImg,
  mobileCommission,
  close,
  headerOne,
  headerTwo,
  securelogo,
  tick,
  line,
  heroIcons,
  bottomBtn,
  smallIcons,
  ombud,
  ofgem,
  mse,
  tim,
  thousands,
  tab1,
  tab2,
  tab3,
  person1,
  person2,
  person3,
  vector,
  securefooter,
  faqArrow,
  dev,
  tick2,
  blackline,
  refundMyBusinessLogo,
  heroBackground,
  whiteLogo,
  magnifyingGlass,
  lightBlueArrow,
  faqDownArrow,
  thisIsMoney,
  greenTickCircle,
  eSignature,
};

export { img };
