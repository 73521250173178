import styled from "styled-components";

export const OuterContainer = styled.div`
  background: white;
  padding: 69px 80px 83px;
  @media (max-width: 500px) {
    padding: 35px 24px 52px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  @media (max-width: 500px) {
    flex-direction: column;

    button:last-child {
      margin-top: 24px;
    }
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    flex-direction: column;
  }

  button {
    @media (max-width: 500px) {
      margin-left: 0;
    }
  }
`;

export const Left = styled.div`
  width: 600px;
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (min-width: 1000px) and (max-width: 1350px) {
    width: 450px;
    margin-right: 50px;
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    width: 100%;
    margin-right: 0px;
  }
  button {
    margin: 0px;
    margin-top: 32px;
  }
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  margin-bottom: 32px;
  margin-top: 0;
  width: 95%;
  span {
    color: #269cd4;
  }
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 27px;
  width: 95%;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    width: 98%;
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    max-width: 635px;
  }
`;
