import { ActionCreator, Dispatch } from "redux";
import {
  finalObj,
  UPDATING_SHOWING,
  UpdatingShowingT,
} from "../types/ShowingQuestion.types";

export const FinalUpdateAction: ActionCreator<UpdatingShowingT> = (
  payload: finalObj
) => {
  return { type: UPDATING_SHOWING, payload: payload };
};

export const UpdateShowingQuestion = (payload: any): any => {
  return async (dispatch: Dispatch, getState: any) => {
    let formObj = getState()?.UpdateFinalObjReducer; // Get Store
    console.log("inside update step");

    console.log({ payload });

    const updatedDetails = {
      ...formObj,
      showing: payload,
    };

    // Do some local logic to build my new state

    dispatch(FinalUpdateAction(updatedDetails)); // Pass new local state into action to update main store
  };
};
