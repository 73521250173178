import styled from "styled-components";
import { theme } from "../../theme/theme";
import { CenteredRowMixin, iSCOpen } from "../../theme/reuseable";

export const FaqWrapper = styled.div<iSCOpen>`
  margin-bottom: 26px;
  width: 971px;

  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
    ${CenteredRowMixin};
    justify-content: space-between;
    color: ${(props: iSCOpen) => (props.open ? `  #00A6F5;` : ` #101a5a;`)};

    :hover {
      cursor: pointer;
    }

    .arrow {
      fill: white;
      transition: all 0.3s ease-in-out;
      width: 16px;
      height: 8px;
    }
    .open {
      fill: white;
      transform: rotate(-180deg);

      path {
        stroke: rgba(0, 166, 245, 1);
      }
    }
    .closed {
      transform: rotate(0);

      path {
        stroke: ${theme.colors.navy};
      }
    }
  }

  // Text container
  > div {
    background-color: white;
    padding-inline: 24px;
    transition: 0.3s linear;

    ${(props: iSCOpen) =>
      props.open
        ? ` max-height: 350px;  padding-block: 16px;`
        : ` max-height: 0px; overflow: hidden;`};

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 16px;
      color: #101a5a;

      a,
      a:visited {
        color: #101a5a;
      }
      span {
        color: rgba(228, 111, 26, 1);
        text-decoration: underline;
        cursor: pointer;
      }
    }
    ul {
      li {
        font-weight: 400;
        color: #101a5a;

        font-size: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    width: 327px !important;

    > div {
      ${(props: iSCOpen) =>
        props.open
          ? ` max-height: 450px;  padding-block: 16px;`
          : ` max-height: 0px; overflow: hidden;`};
    }

    h6 {
      font-size: 16px;
      line-height: 26px;
      gap: 20px;
      /* width: 280px; */
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 1020px) and (min-width: 500px) {
    width: 100%;
    h6 {
      width: 90%;
    }
  }
`;

export const Text = styled.p`
  @media (max-width: 500px) {
  }
`;

export const List = styled.ul`
  background-color: white;
  padding: 0px 24px;
  margin-bottom: 16px;
`;

export const ListItem = styled.li`
  margin-bottom: 7px;
`;
