import React, { ReactNode } from "react";
import { img } from "../../assets/imageFolder";
import { Line, TickWrapper } from "./styles";

export interface iHeroTickProps {
  children: ReactNode;
  underline?: boolean;
}

const HeroTick: React.FC<iHeroTickProps> = ({ children, underline }) => {
  return (
    <TickWrapper>
      <img src={img.tick} />
      {underline && <Line src={img.line} />}

      {children}
    </TickWrapper>
  );
};

export default HeroTick;
