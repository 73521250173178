import React, { ReactNode } from "react";
import { img } from "../../assets/imageFolder";
import { iTestimonialObj } from "../../containers/Customers/data";
import { Row } from "../../theme/reuseable";

import { Individual } from "./styles";

export interface iTestimonialProps {
  card: iTestimonialObj;
}

const Testimonial: React.FC<iTestimonialProps> = ({ card }) => {
  return (
    <Individual>
      <q>{card.quote}</q>
      <Row gap="16px">
        <img src={card.img} alt={card.name} />
        <p>{card.name}</p>
      </Row>
    </Individual>
  );
};

export default Testimonial;
