import React from "react";
import { Container, Content, Header, Text } from "./styles";

const Right = () => {
  return (
    <Container>
      <Content>
        <Header>Uncovering and recovering your costs </Header>
        <Text>
          UK business owners could already be looking at paying
          <span> four times</span> the amount for business energy as they paid
          in 2020: our team is here to help you tackle these rising costs and
          see if you have a claim for compensation.
        </Text>
      </Content>
    </Container>
  );
};

export default Right;
