import {
  stateObj,
  updateQuestion,
  UPDATING_QUESTIONS,
  UpdatingQuestionT,
} from "../types/UpdateShowingObj.types";

const initialQuestionState: stateObj = {
  one: true,
  two: false,
  three: false,
  four: false,
  five: false,
  six: false,
  thank: false,
};

export const UpdateShowingObjReducer = (
  state: stateObj = initialQuestionState,
  action: UpdatingQuestionT
): stateObj => {
  switch (action.type) {
    case UPDATING_QUESTIONS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
