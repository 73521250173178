import styled from "styled-components";

export const Container = styled.header`
  background: #ffffff;
  padding: 0 80px;


`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1300px;
  @media (min-width: 1500px) {
    margin: auto;
  }
`;

export const FiveStar = styled.img``;
export const Secure = styled.img`
  margin-left: 36px;

  @media (max-width: 580px) {
    margin: unset;
  }
`;

export const MobileContainer = styled.header`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 0 24px;
  .logo-mobile {
    cursor: pointer;
    width: 60px;
    height: 60px;
    path {
      fill: rgb(16, 26, 90);
    }

    .cls-4 {
      fill: #fff;
    }
    .cls-2 {
      fill: #a8cf45;
    }
  }
  img:last-child {
    width: 75px;
  }
`;

export const MobileImage = styled.img``;

export const HeaderText = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1e2241;
  margin-left: 38px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  .logo {
    cursor: pointer;
    width: 80px;
    height: 80px;
    path {
      fill: rgb(16, 26, 90);
    }

    .cls-4 {
      fill: #fff;
    }
    .cls-2 {
      fill: #a8cf45;
    }
    @media (max-width: 580px) {
      width: 60%;
    }
  }
`;
