import styled from "styled-components";
import { img } from "../../../assets/imageFolder/index";

export const Container = styled.div`
  width: 435px;
  height: 579px;
  background-image: url(${img.vector});
  @media (max-width: 600px) {
    background-image: url(${img.vectorMobile});
    background-repeat: no-repeat;
    width: 327px;
    height: 526px;
  }
  position: relative;

  @media (max-width: 1070px) and (min-width: 1000px) {
    float: right;
    position: absolute;

    top: 3%;
    right: -7%;
  }
  @media (max-width: 999px) {
    margin: auto;
    margin-top: 39px;
  }
`;

export const Content = styled.div`
  position: absolute;
  bottom: 27px;
  width: 100%;
  @media (max-width: 600px) {
    bottom: 18px;
  }
`;

export const Header = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  width: 372px;
  margin: auto;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 32px;
    width: 288px;
  }
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  width: 372px;
  margin: auto;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    width: 288px;
  }
  span {
    font-weight: 700;
  }
`;
