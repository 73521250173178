import React from "react";
import { useNavigate } from "react-router-dom";
import { img } from "../../assets/imageFolder";
import { ReactComponent as WhiteLogo } from "../../assets/imageFolder/whiteLogo.svg";

import useWindowSize, { Size } from "../../utilities/Hooks/useWindowSize";
import { Container, HeaderText, Inner, Left, Secure } from "./styles";

const Header = () => {
  const size: Size = useWindowSize();
  const navigate = useNavigate();
  return (
    <Container>
      <Inner>
        <Left>
          <WhiteLogo className="logo" onClick={() => navigate("/")} />
          {size.width! > 1110 ? (
            <HeaderText>
              Over 4 million companies could be eligible to claim
            </HeaderText>
          ) : (
            ""
          )}
        </Left>
        <div>
          <Secure src={img.headerTwo} />
        </div>
      </Inner>
    </Container>
  );
};

export default Header;
