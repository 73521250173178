export interface iClaimObj {
  signature: string;
  brokerUsed: boolean;
}

export const UPDATING_CLAIM = "UPDATING_CLAIM";

export interface iClaim {
  type: typeof UPDATING_CLAIM;
  payload: iClaimObj;
}
export type ClaimT = iClaim;
