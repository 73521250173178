import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Ticks from "../components/Ticks";
import useWindowSize, { Size } from "../utilities/Hooks/useWindowSize";
import MobileHeader from "../layout/Header/MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionOne from "components/Questions/QuestionOne";
import QuestionTwo from "components/Questions/QuestionTwo";
import QuestionThree from "components/Questions/QuestionThree";
import QuestionFour from "components/Questions/QuestionFour";
import QuestionFive from "components/Questions/QuestionFive";
import QuestionSix from "components/Questions/QuestionSix";
import QuestionSeven from "components/Questions/QuestionSeven";
import QuestionEight from "components/Questions/QuestionEight";

interface props {
  reengage: boolean;
}

const Form = ({ reengage }: props) => {
  const questionIndex = useSelector(
    (rootReducer: RootState) => rootReducer?.ShowingQuestionReducer.showing
  );
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [questionIndex]);
  const size: Size = useWindowSize();

  useEffect(() => {
    
  }, [mounted]);

  const questionsArr = [
    <QuestionOne />,
    <QuestionTwo />,
    <QuestionThree />,
    <QuestionFour />,
    <QuestionFive />,
    <QuestionSix />,
    <QuestionSeven />,
    <QuestionEight />,
  ];

  return (
    <>
      {size.width! > 625 ? <Header /> : <MobileHeader />}
      <div>{questionsArr[questionIndex]}</div>
      <Ticks />
      <Footer />
    </>
  );
};

export default Form;
