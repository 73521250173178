import {
  finalObj,
  UPDATING_SHOWING,
  UpdatingShowingT,
} from "../types/ShowingQuestion.types";

export const initialFinalObj: finalObj = {
  showing: 0,
};

export const ShowingQuestionReducer = (
  state: finalObj = initialFinalObj,
  action: UpdatingShowingT
): finalObj => {
  switch (action.type) {
    case UPDATING_SHOWING: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
