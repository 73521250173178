import React, { useEffect } from "react";
import {
  Container,
  NavyNext,
  Head,
  InnerContainer,
  Next,
  NextCont,
  TestContainer,
} from "../QuestionSix/styles";

import { useDispatch } from "react-redux";
import { ClearLead } from "../../../redux/actions/UpdateLead.actions";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { useNavigate } from "react-router-dom";
import { updateClaim } from "../../../redux/actions/claim.actions";

const QuestionTwo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (answer: boolean) => {
    if (answer) {
      dispatch(updateClaim("brokerUsed", answer)).then(() =>
        dispatch(UpdateShowingQuestion(2))
      );
    } else {
      dispatch(
        ClearLead({
          phone: "",
          leadId: 0,
        })
      );
      navigate("/sorry");
    }
  };

  return (
    <Container>
      <InnerContainer>
        <TestContainer>
          <Head>
            Have you EVER used an energy broker to arrange your business energy
            deal?
          </Head>
          <NextCont>
            <Next onClick={() => handleChange(true)}>YES</Next>
            <NavyNext onClick={() => handleChange(false)}>NO</NavyNext>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionTwo;
