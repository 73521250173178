import React from "react";
import { Container, Header, SubHeader, Testimonials } from "./styles";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { iTestimonialObj, testimonialData } from "./data";
import Testimonial from "../../atoms/Testimonial";

const Customers = () => {
  const navigate = useNavigate();
  const testimonials = testimonialData.map((card: iTestimonialObj) => (
    <Testimonial key={card.name} card={card} />
  ));
  return (
    <Container>
      <Header>
        We’ve Helped Customers <span>Claim Back Money!</span>
      </Header>
      <SubHeader>
        Here are just a few business owners like you that Refund My Business
        Energy has helped make a claim on their business energy deal.
      </SubHeader>
      <Testimonials>{testimonials}</Testimonials>

      <Button
        btnText="CHECK FOR FREE"
        btnImg
        onClick={() => navigate("/form")}
      />
    </Container>
  );
};

export default Customers;
