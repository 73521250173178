export const theme = {
  colors: {
    white: "#FFFFFF;",
    black: "#144158;",
    orange: "#EEAC02;",
    grey: "#F7F9FB;;",
    orangeButton: "#EE6502",
    navy: "#101A5A",
    lightBlue: "#D0E7F2;",
    blueHeader: "#00A6F5;",
  },
  fontFamily: {
    primary: "Usual",
    secondary: "",
  },
  fontSize: {
    h1: "60px",
    h2: "52px",
    h3: "38px",
    h4: "28px",
    h5: "25px",
  },
  content: {
    maxWidth: "1212px",
  },
};
