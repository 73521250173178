import React from "react";
import { img } from "../../assets/imageFolder";
import { OrangeButton } from "./styles";
// import { iButton } from "./types";

const Button: React.FC<any> = ({ btnText, onClick, btnImg, loading }) => {
  return (
    <OrangeButton onClick={onClick} disabled={loading}>
      {btnText}
      {btnImg ? <img src={img.arrow} alt={btnText} /> : null}
    </OrangeButton>
  );
};

export default Button;
