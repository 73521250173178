import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Ticks from "../components/Ticks";

import { Heading } from "../layout/Hero/styles";
import { RootState } from "../redux/reducers";
import { ThankYouWrapper, ThankYouContent } from "./styles";
import useWindowSize, { Size } from "../utilities/Hooks/useWindowSize";
import MobileHeader from "../layout/Header/MobileHeader";
import Button from "../components/Button";

const ThankYou = () => {
  const navigate = useNavigate();
  const business = useSelector(
    (rootReducer: RootState) => rootReducer?.UpdateFinalObjReducer
  );

  console.log(business, business.lead, "BUSINESS");
  const size: Size = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     function gtag(one: any, two: any, three?: any) {
  //       window.dataLayer.push(arguments);
  //     }
  //     gtag("js", new Date());
  //     gtag("event", "conversion", {
  //       send_to: "",
  //     });
  //   }, 1000);
  // }, []);

  return (
    <ThankYouWrapper>
      {size.width! > 625 ? <Header /> : <MobileHeader />}

      <section className="inner">
        <Heading>Thank you, {business?.lead?.firstName}!</Heading>
        <ThankYouContent>
          You will now receive a call in the next 48 hours from our partners at
          the Ethical Business Group from this telephone number: 0167 063 9031.
        </ThankYouContent>
        <Button btnText="RETURN TO HOME" onClick={() => navigate("/")} />
      </section>
      <Ticks />
      <Footer />
    </ThankYouWrapper>
  );
};

export default ThankYou;
