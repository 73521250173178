import React from "react";
import {
  AllLines,
  Container,
  Header,
  Left,
  LineContainer,
  OuterContainer,
  RightContainer,
  Text,
} from "./styles";
import Right from "./Right";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const HowItWorks = () => {
  const navigate = useNavigate();
  return (
    <OuterContainer>
      <Container>
        <Left>
          <Header>
            How <span> Broker Commissions </span>Work
          </Header>
          <Text>
            Here’s just one example of how some UK company and business owners
            are being overcharged for their business energy deals. Is your deal
            also worth checking?
          </Text>
          <AllLines>
            <LineContainer>
              <p>
                How much you should be paying <span>12p/kWh</span>
              </p>
            </LineContainer>
            <LineContainer>
              <p>
                How much commission your broker earns <span>5p/kWh </span>
              </p>
            </LineContainer>
            <LineContainer>
              <p>
                <span>Your bill will show 17p/kWh </span>(which you pay to your
                supplier)
              </p>
            </LineContainer>
            <LineContainer>
              <p>
                Your supplier pays the broker the <span>5p/kWh</span> from your
                contract
              </p>
            </LineContainer>
          </AllLines>

          <Button
            btnText="CHECK FOR FREE"
            btnImg
            onClick={() => navigate("/form")}
          />
        </Left>
        <RightContainer>
          <Right />
        </RightContainer>
      </Container>
    </OuterContainer>
  );
};

export default HowItWorks;
