import styled from "styled-components";
import { theme } from "../../theme/theme";

export const Container = styled.div`
  padding: 70px 80px 61px;
  background-color: ${theme?.colors?.grey};
  @media (max-width: 500px) {
    padding: 33px 24px 47px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  gap: 80px;
  > div {
    width: clamp(80%, 600px, 100%);
  }
  max-width: 1300px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (min-width: 1500px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    gap: 0px;
  }
`;

export const TopLeft = styled.div`
  width: 600px;
  p {
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 27px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #101a5a;
  }
  @media (max-width: 500px) {
    width: 327px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    width: 400px;
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    width: 100%;
  }
`;

export const TopRight = styled.div`
  width: 580px;
  position: relative;
  margin-top: 74px;
  button {
    float: left;
  }
  @media (max-width: 500px) {
    width: 327px;
    margin-top: 0px;
    button {
      float: none;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    width: 400px;
    button {
      float: none;
    }
  }
  @media (min-width: 500px) and (max-width: 1000px) {
    width: 100%;
    button {
      float: none;
    }
  }
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  color: #1e2241;
  margin-bottom: 32px;

  span {
    color: #269cd4;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 500px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
`;

export const HeaderSpan = styled.span`
  color: #269cd4;
`;

export const Text = styled.p``;

export const Thousands = styled.img`
  position: absolute;
  top: -10px;
  left: 0;
  z-index: -1;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SubHeader = styled.h3`
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 103px;

  strong {
    font-weight: 700;
  }
  @media (max-width: 500px) {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 0;
  }
`;

// export const ButtonText = styled.p`
//   font-family: "SF Pro Display";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 26px;
//   line-height: 31px;
//   text-align: center;
//   letter-spacing: 0.04em;
//   text-transform: uppercase;
//   color: #ffffff;
// `;

export const BottomContainer = styled.div``;
