import Parser from "html-react-parser";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/imageFolder/faqArrow.svg";
import { iFaq } from "../../containers/Faqs/types";
import { FaqWrapper } from "./styles";

export interface iFaqProps {
  faq: iFaq;
  open: boolean;
  setOpenHeader: Dispatch<SetStateAction<string>>;
}
const Faq: React.FC<iFaqProps> = ({ faq, open, setOpenHeader }) => {
  const { header } = faq;

  const handleClickTitle = () => {
    open ? setOpenHeader("") : setOpenHeader(header);
  };

  const text = faq.paras.map((p: string) => <p key={p}>{Parser(p)}</p>);

  const list = faq.list
    ? faq.list.map((li: string) => <li key={li}>{li}</li>)
    : null;

  const lastLine = faq.lastPara.length
    ? faq.lastPara.map((p: string) => <p key={p}>{Parser(p)}</p>)
    : null;

  return (
    <FaqWrapper key={header} open={open}>
      <h6 onClick={handleClickTitle}>
        {header}

        <Arrow
          fill="current"
          stroke="current"
          className={open ? "arrow open" : "arrow closed"}
        />
      </h6>

      <div>
        {text}
        {faq.list ? <ul>{list}</ul> : null}
        {lastLine}
      </div>
    </FaqWrapper>
  );
};

export default Faq;
