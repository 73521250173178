import { env } from "../../env";

const getBusinesses = async (busName: string) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://api.company-information.service.gov.uk/search/companies?q=${busName}&items_per_page=20`
      );
      xhr.setRequestHeader(
        "Authorization",
        `Basic ${btoa(`${env.REACT_APP_COMPANIES_HOUSE_API_KEY}`)}`
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      xhr.send();
    } catch (e) {
      reject(e);
    }
  });
};

export default getBusinesses;
