import styled from "styled-components";
import { theme } from "../../theme/theme";

export const Container = styled.div`
  background: ${theme?.colors?.navy};
  padding-top: 63px;
  padding-bottom: 57px;

  @media (max-width: 500px) {
    padding: 39px 24px;
  }
  button {
    @media (max-width: 500px) {
      margin-top: 0px;
    }
  }
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 16px;
  span {
    color: #269cd4;
  }
  @media (max-width: 500px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }
`;

export const SubHeader = styled.p`
  width: 810px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  margin-bottom: 66px;
  @media (max-width: 1000px) {
    width: 90%;
    font-weight: 400;
    text-align: left;
  }

  @media (max-width: 500px) {
    width: 100%;

    font-size: 14px;
    line-height: 24px;
  }
`;

export const Testimonials = styled.div`
  margin: 0 84px;

  display: flex;
  gap: 60px;
  max-width: 1300px;
  @media (min-width: 1600px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    margin: 0;
    gap: 30px;
    margin-bottom: 32px;
  }

  @media (max-width: 810px) {
    flex-direction: column;
  }
  justify-content: space-between;
`;
