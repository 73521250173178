import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-size: 62.5%;
    height: 100%;
    margin: 0;
    max-width: 100vw;
  }

  body {
    overflow-x: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }


footer{ 
  margin-top: auto;
}


`;
