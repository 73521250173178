export interface leadObj {
  phone: string;
  leadId: number;
  reengage: boolean;
}

export const UPDATING_LEAD = "UPDATING_LEAD";

export interface updateLead {
  type: typeof UPDATING_LEAD;
  payload: leadObj;
}
export type UpdatingLeadT = updateLead;
