import React from "react";

import { MobileContainer, MobileImage } from "./styles";
import { useNavigate } from "react-router-dom";
import { img } from "../../assets/imageFolder";
import { ReactComponent as WhiteLogo } from "../../assets/imageFolder/whiteLogo.svg";


const MobileHeader = () => {
  const navigate = useNavigate();

  return (
    <MobileContainer>
      <WhiteLogo className="logo-mobile" onClick={() => navigate("/")} />
      <MobileImage src={img.securelogo} />
    </MobileContainer>
  );
};

export default MobileHeader;
