import React from "react";
import { img } from "../../assets/imageFolder";
import { BlackLine, Container, Guarantee, OuterContainer } from "./styles";

const Ticks = () => {
  return (
    <OuterContainer>
      <Container className="ss">
        <Guarantee>
          <img src={img.greenTickCircle} />
          <p>
            You may have a <span> MIS-SOLD </span>business energy deal
          </p>
        </Guarantee>
        <Guarantee>
          <img src={img.greenTickCircle} />
          <BlackLine src={img.blackline} />
          <p>
            OFGEM have <span> MADE </span>energy brokers pay back their hidden
            commissions
          </p>
        </Guarantee>
        <Guarantee>
          <img src={img.greenTickCircle} />
          <p>
            Get your payout or pay <span> NOTHING</span>
          </p>
        </Guarantee>
      </Container>
    </OuterContainer>
  );
};

export default Ticks;
