import React from "react";
import {
  Container,
  Header,
  Left,
  OuterContainer,
  RightContainer,
  Text,
} from "./styles";
import Right from "./Right";
import useWindowSize, { Size } from "../../utilities/Hooks/useWindowSize";
import { Link, useNavigate } from "react-router-dom";
import { img } from "../../assets/imageFolder";
import Button from "../../components/Button";

const Warning = () => {
  const navigate = useNavigate();

  const size: Size = useWindowSize();

  return (
    <OuterContainer>
      <Container>
        <Left>
          <Header>
            What Are The Signs Of Being
            <span> Mis-Sold?</span>
          </Header>
          <Text>
            Many business owners have often just accepted increased costs of
            their business energy deals, especially in the current financial
            climate.
          </Text>
          <Text>
            But for thousands of them, they may have been mis-sold their plan by
            their energy broker.
          </Text>
          <Text>
            Our expert team have identified the key warning signs to help you
            find out if you were potentially mis-sold your business energy
            package, and could be due financial compensation.
          </Text>
          {size.width! > 1000 && (
            <Button
              btnText="CHECK FOR FREE"
              btnImg
              onClick={() => navigate("/form")}
            />
          )}
        </Left>
        <RightContainer>
          <Right />
        </RightContainer>
        {size.width! < 1000 && (
          <Button
            btnText="CHECK FOR FREE"
            btnImg
            onClick={() => navigate("/form")}
          />
        )}
      </Container>
    </OuterContainer>
  );
};

export default Warning;
