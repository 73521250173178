import styled from "styled-components";

export const Individual = styled.div`
  width: clamp(300px, 100%, 373px);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  padding: 22px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  q {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 18px;
    width: 98%;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }

  > div {
    margin-top: 18px;
  }

  @media (max-width: 900px) {
    margin-right: 0px;
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1400px) {
    margin-right: 0px;

    div {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 18px;
    }
  }

  @media (max-width: 500px) {
    width: unset;

    min-width: unset;
    max-width: unset;
    margin-right: 0px;
    padding: 22px 19px;
    margin-bottom: unset;

    q {
      font-size: 14px;
      line-height: 24px;
      width: 98.5%;
    }
  }
`;
