import { ActionCreator, Dispatch } from "redux";
import {
  leadObj,
  UPDATING_LEAD,
  updateLead,
  UpdatingLeadT,
} from "../types/UpdateLead.types";

export const LeadUpdateAction: ActionCreator<UpdatingLeadT> = (
  payload: leadObj
) => {
  return { type: UPDATING_LEAD, payload: payload };
};

export const UpdateLead = (payload: any, key: any): any => {
  return async (dispatch: Dispatch, getState: any) => {
    let formObj = getState()?.UpdateLeadReducer; // Get Store

    const updatedDetails = { ...formObj, [key]: payload };

    // Do some local logic to build my new state

    dispatch(LeadUpdateAction(updatedDetails)); // Pass new local state into action to update main store
  };
};

export const ClearLead = (payload: any): any => {
  return async (dispatch: Dispatch, getState: any) => {
    console.log("CLEAR LEAD");

    dispatch(LeadUpdateAction(payload)); // Pass new local state into action to update main store
  };
};
