import React from 'react';
import {
  Container,
  Header,
  Inner,
  Postcode,
  SubHeader,
  TopContainer,
} from "./styles";
import bottomBtn from "../../../assets/imageFolder/button.svg";
import smallIcons from "../../../assets/imageFolder/smallIcons.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Notify } from "notiflix";
import { Navigate } from "react-router-dom";
import Button from "../../../components/Button";
import { useDispatch } from 'react-redux';
import { UpdateFinalObj } from 'redux/actions/UpdateFinalObj.actions';
import { LendersList } from './data';

const Right = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lenders, setLenders] = useState<string[]>([]);
  const [choosedLender, setChoosedLender] = useState<string>("");

  const onChange = (e: any) => {
    setChoosedLender(e.target.value);

    handleSearch(e.target.value);
  };

  const onClick = () => {
    if (choosedLender.length === 0) {
      Notify.failure("Please provide who supplies your business energy");
    } else {
      dispatch(UpdateFinalObj(choosedLender, "company", "BusinessLender"));
      navigate("/form");
    }
  };

  const handleSearch = (value: string) => {
    if (!value) {
      return setLenders([]);
    }

    const filtersearch = LendersList.filter((lender : any) => {
      const lenderName = lender.toString().toLowerCase();
      const lenderSearch = value.toString().toLowerCase();

      if (lenderName.includes(lenderSearch)) {
        return lender;
      }
    });
    setLenders(filtersearch);
  };

  const handleClick = (event : any) => {
    let value = event.target.getAttribute("data-value");
        
    setChoosedLender(value);
    setLenders([]);
  };

  return (
    <Container>
      <Inner>
        <SubHeader>RISK-FREE CLAIM IN 60 SECONDS</SubHeader>
        <TopContainer>
          <Header>Find Out Now If You’re Owed Money Back </Header>
          <img src={bottomBtn} />
        </TopContainer>
        <div className="input-container">
          <Postcode
            type="text"
            placeholder="BUSINESS ENERGY SUPPLIER"
            onChange={onChange}
            value={choosedLender}
          />
          {lenders.length > 0 && (
            <ul className="dropdown-results">
              {Array.isArray(lenders) &&
                lenders.map((lender, i) => {
                  return (
                    <li
                      key={i}
                      onClick={handleClick}
                      data-name="lender"
                      data-value={lender}
                    >
                      {lender}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
        <Button onClick={onClick} btnText="CHECK FOR FREE" btnImg />

        <img src={smallIcons} />
      </Inner>
    </Container>
  );
};

export default Right;
