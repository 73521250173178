import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  position: relative;
  @media (max-width: 1070px) {
    flex-direction: column;
  }
`;

export const OuterContainer = styled.div`
  background: white;
  padding: 85px 136px 85px 84px;
  @media (max-width: 1092px) and (min-width: 500px) {
    padding: 85px 100px;
  }
  @media (max-width: 500px) {
    padding: 34px 24px 58px;
  }
`;

export const Left = styled.div`
  width: 600px;
  button {
    margin: 0;
    @media (max-width: 500px) {
      margin-top: 8px;
    }
  }

  @media (max-width: 1285px) and (min-width: 1070px) {
    width: 50%;
  }
  @media (max-width: 1070px) and (min-width: 500px) {
    width: 100%;
  }
  @media (max-width: 999px) {
    width: 100%;
  }
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
  }

  span {
    color: #269cd4;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 9px;
  @media (max-width: 1070px) and (min-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 999px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 24px;
    width: 99%;
    margin-bottom: 24px;
  }
`;

export const AllLines = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LineContainer = styled.div`
  background: #d0e7f2;
  padding: 17px 19px;
  margin-bottom: 24px;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    letter-spacing: -0.01em;

    color: #080d2d;

    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1070px) and (min-width: 1000px) {
    width: 48%;
  }
  @media (max-width: 999px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    margin-bottom: 16px;
    width: unset;

    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

export const LineText = styled.p`
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
`;

export const RightContainer = styled.div``;
