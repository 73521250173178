import { UpdateFinalObj } from "../../../redux/actions/UpdateFinalObj.actions";
import {
  Container,
  DetailsContainer,
  Head,
  InnerContainer,
  Input,
  Label,
  LabelCont,
  Next,
  NextCont,
  TestContainer,
} from "./styles";
import React, { useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { useEffect } from "react";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { useDispatch } from "react-redux";
import { Loading } from "notiflix";

const QuestionFour = () => {
  const [bad, setBad] = useState({
    first: false,
    last: false,
  });
  const [nameObj, setNameObj] = useState({
    first_name: "",
    last_name: "",
  });

  const [exit, setExit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  const onClick = async () => {
    if (nameObj.first_name.length === 0 || nameObj.last_name.length === 0) {
      setBad({
        first: nameObj.first_name.length === 0,
        last: nameObj.last_name.length === 0,
      });
      Notify.failure("Please make sure both inputs are filled");
    } else {
      Loading.standard("Loading...");

      await dispatch(UpdateFinalObj(nameObj.first_name, "lead", "firstName"));
      await dispatch(
        UpdateFinalObj(nameObj.last_name, "lead", "lastName")
      ).then(() => {
        Loading.remove();
        dispatch(UpdateShowingQuestion(4))
      })
    }
  };

  const onChange = (e: any) => {
    const { value } = e.target;

    let tester = true;

    tester = /^[a-zA-Z-]+$/.test(value);

    if (tester || value === "")
      setNameObj({ ...nameObj, [e.target.id]: e.target.value });
  };

  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <TestContainer>
          <Head>You could have a claim! Please enter your details below</Head>
          <DetailsContainer>
            <LabelCont>
              <Label>First Name</Label>
              <Input
                type="text"
                id="first_name"
                value={nameObj.first_name}
                onChange={onChange}
                className={bad.first ? "bad" : "default"}
              ></Input>
            </LabelCont>
            <LabelCont>
              <Label>Surname</Label>
              <Input
                type="text"
                id="last_name"
                value={nameObj.last_name}
                onChange={onChange}
                className={bad.last ? "bad" : "default"}
              ></Input>
            </LabelCont>
          </DetailsContainer>

          <NextCont>
            <Next onClick={onClick}>NEXT</Next>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionFour;
