export interface finalObj {
  lead: {
    businessName: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  company: {
    companyNumber: number;
    officer: string;
    lastFiling: string;
    companyStatus: string;
    companyType: string;
    dateOfCreation: string;
    description: string;
    infoDump: any;
    monthlyBill: number;
  };
  address: {
    addressLineOne: string;
    addressLineTwo: string;
    addressLineThree: string;
    city: string;
    postcode: string;
  };
}

export const UPDATING_FINAL = "UPDATING_FINAL";

export interface updateFinal {
  type: typeof UPDATING_FINAL;
  payload: finalObj;
}
export type UpdatingFinalT = updateFinal;
