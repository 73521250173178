import React, { useEffect } from "react";
import Footer from "../layout/Footer";
import Customers from "../containers/Customers";
import FaqNewer from "../containers/Faqs";
import Featured from "../containers/Featured";
import Hero from "../layout/Hero";
import HowItWorks from "../containers/HowItWorks";
import OwedThousands from "../containers/OwedThousands";
import Providers from "../containers/Providers";
import Warning from "../containers/Warning";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero />
      <Featured />
      <Providers />
      <OwedThousands />
      <Warning />
      <Customers />
      <HowItWorks />
      <FaqNewer />
      <Footer />
    </div>
  );
};

export default Landing;
