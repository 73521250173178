import { iClaimObj, UPDATING_CLAIM, ClaimT } from "../types/claim.types";

export const initClaim: iClaimObj = {
      signature: "",
  brokerUsed: false,
};

export const claimReducer = (
  state: iClaimObj = initClaim,
  action: ClaimT
): iClaimObj => {
  switch (action.type) {
    case UPDATING_CLAIM: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
