import { UpdateFinalObj } from "../../../redux/actions/UpdateFinalObj.actions";
import {
  Container,
  DetailsContainer,
  Head,
  InnerContainer,
  Input,
  Label,
  LabelCont,
  Next,
  NextCont,
  TestContainer,
} from "./styles";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import Notiflix, { Notify } from "notiflix";

const QuestionThree = () => {
  const [monthlyCost, setMonthlyCost] = useState(0);

  const [exit, setExit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  const onClick = async () => {
    if (monthlyCost < 2000) {
      Notify.failure(
        "Sorry, we can only proceed when the monthly cost is over £2,000."
      );
    } else {
      await dispatch(UpdateFinalObj(monthlyCost, "company", "monthlyBill"));
      dispatch(UpdateShowingQuestion(3));
    }
  };

  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <TestContainer>
          <Head>Please enter your monthly bill cost</Head>
          <DetailsContainer>
            <LabelCont>
              <Label>Monthly bill cost</Label>
              <Input
                type="number"
                id="monthlyBill"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMonthlyCost(e.target.valueAsNumber)
                }
              ></Input>
            </LabelCont>
          </DetailsContainer>

          <NextCont>
            <Next onClick={onClick}>NEXT</Next>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionThree;
