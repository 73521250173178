import React from 'react';
import { img } from "../../assets/imageFolder";
import {
  Container,
  Image,
  ImagesContainer,
  InnerContainer,
  Text,
} from "./styles";

const Featured = () => {
  return (
    <Container>
      <InnerContainer>
        <Text>Business Energy Claims Have Been Featured In:</Text>
        <ImagesContainer>
          <Image prop="1" src={img.ombud} />
          <Image prop="2" src={img.ofgem} />
          <Image prop="3" src={img.mse} />
          <Image prop="4" src={img.thisIsMoney} />
        </ImagesContainer>
      </InnerContainer>
    </Container>
  );
};

export default Featured;
