import React from "react";
import {
  Heading,
  InnerHero,
  Section,
  SubHeader,
  TicksContainer,
} from "./styles";

import Header from "../../layout/Header";
import MobileHeader from "../../layout/Header/MobileHeader";
import useWindowSize, { Size } from "../../utilities/Hooks/useWindowSize";
import HeroTick from "../../atoms/HeroTick";
import { img } from "../../assets/imageFolder";
import Right from "./right";

const Hero = () => {
  const { width }: Size = useWindowSize();
  return (
    <Section>
      {width! > 625 ? <Header /> : <MobileHeader />}
      <InnerHero>
        <div>
          {width! > 625 && (
            <SubHeader>Are you a Ltd company owner in the UK?</SubHeader>
          )}

          <Heading>
            UK Businesses Are Finally Receiving<span> £10,000s </span>
            Back In <span>Energy Refunds</span>
          </Heading>

          {width! > 900 ? (
            <>
              <TicksContainer>
                <HeroTick
                  children={
                    <h6>
                      You may have a <strong> MIS-SOLD </strong>business energy
                      deal
                    </h6>
                  }
                />
                <HeroTick
                  underline
                  children={
                    <h6>
                      OFGEM have <strong>MADE </strong>energy brokers pay back
                      their hidden commissions
                    </h6>
                  }
                />
                <HeroTick
                  children={
                    <h6>
                      Get your payout or pay <strong>NOTHING</strong>
                    </h6>
                  }
                />
              </TicksContainer>

              <img src={img.heroIcons} alt="5 Star Rating and Secure" />
            </>
          ) : null}
        </div>

        <Right />
        {width! < 900 ? (
          <>
            <TicksContainer>
              <HeroTick
                children={
                  <h6>
                    You may have a <strong> MIS-SOLD </strong>business energy
                    deal
                  </h6>
                }
              />
              <HeroTick
                underline
                children={
                  <h6>
                    OFGEM have <strong>MADE </strong>energy brokers pay back
                    their hidden commissions
                  </h6>
                }
              />
              <HeroTick
                children={
                  <h6>
                    Get your payout or pay <strong>NOTHING</strong>
                  </h6>
                }
              />
            </TicksContainer>

            <img src={img.heroIcons} alt="5 Star Rating and Secure" />
          </>
        ) : null}
      </InnerHero>
    </Section>
  );
};

export default Hero;
