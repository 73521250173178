import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SorryWrapper, SubHeading } from "./styles";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Ticks from "../components/Ticks";
import { Heading } from "../layout/Hero/styles";
import useWindowSize, { Size } from "../utilities/Hooks/useWindowSize";
import MobileHeader from "../layout/Header/MobileHeader";
import Button from "../components/Button";
import { UpdateShowingQuestion } from "../redux/actions/ShowingQuestion.actions";
const Sorry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const size: Size = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(UpdateShowingQuestion(0));
  }, []);
  return (
    <SorryWrapper>
      {size.width! > 625 ? <Header /> : <MobileHeader />}

      <section className="inner">
        <Heading>We’re Sorry.</Heading>
        <SubHeading>
          Based off the information you have supplied to us, it’s unlikely you
          will be able to make a claim for mis-sold business energy.
        </SubHeading>
        <Button btnText="RETURN TO HOME" onClick={() => navigate("/")} />
      </section>
      <Ticks />
      <Footer />
    </SorryWrapper>
  );
};

export default Sorry;
