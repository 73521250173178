import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  color: #101a5a;

  div {
    background: #d0e7f2;
    width: clamp(240px, 42.5%, 289px);
    padding: 14px 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    span {
      font-weight: 700;
    }
  }

  @media (max-width: 750px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    div {
      width: 90%;
    }
  }
`;

interface props {
  prop: string;
}

export const Text = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  width: 98%;
  span {
    font-weight: 700;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 26px;
    width: 97%;
  }
`;

export const Bottom = styled.div`
  background: #101a5a;
  color: white;
  padding: 10px 19px;
  margin-top: 16px;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #ffffff;
    span {
      font-weight: 700;
      color: #ffffff;
    }
  }
  @media (max-width: 500px) {
    width: unset;
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 750px) and (min-width: 500px) {
    width: 100%;
    max-width: 80vw;
  }
`;
