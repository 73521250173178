import { combineReducers } from "redux";
import { ShowingQuestionReducer } from "./ShowingQuestion.reducer";
import { UpdateShowingObjReducer } from "./UpdateShowingObj.reducer";
import { UpdateLeadReducer } from "./UpdateLead.reducer";
import { UpdateFinalObjReducer } from "./UpdateFinalObj.reducer";
import { claimReducer } from "./claim.reducer";


export const rootReducer = combineReducers({
  UpdateShowingObjReducer,
  UpdateLeadReducer,
  UpdateFinalObjReducer,
  ShowingQuestionReducer,
  claimReducer
});

export type RootState = ReturnType<typeof rootReducer>;

