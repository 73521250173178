import React from "react";
import { Bottom, Container, GridContainer } from "./styles";
import useWindowSize, { Size } from "../../../utilities/Hooks/useWindowSize";

const Right = () => {
  const { width } = useWindowSize();
  return (
    <Container>
      <GridContainer>
        <div>
          Are you and your business facing
          <span> higher-than-projected energy costs?</span>
        </div>
        <div>
          Did you suffer losses from energy bills
          <span> higher than they were or should be?</span>
        </div>

        {width! < 600 ? (
          <>
            <div>
              Were you assured everything is fine even as prices began to rise
              and your
              <span> agreement surged in cost?</span>
            </div>
            <div>
              Did you act on the advice you received and enter into a contract
              because of the claims made by a
              <span> broker or third party intermediary (TPI)?</span>
            </div>
          </>
        ) : (
          <>
            <div>
              Did you act on the advice you received and enter into a contract
              because of the claims made by a
              <span> broker or third party intermediary (TPI)?</span>
            </div>
            <div>
              Were you assured everything is fine even as prices began to rise
              and your
              <span> agreement surged in cost?</span>
            </div>
          </>
        )}
      </GridContainer>
      <Bottom>
        <p>
          {" "}
          If you answered <span> yes </span>to any of these questions, then you
          might have been<span> mis-sold your energy agreement</span>, and could
          be in line to claim compensation...
        </p>
      </Bottom>
    </Container>
  );
};

export default Right;
