import {
  Container,
  DetailsContainer,
  Head,
  InnerContainer,
  Next,
  NextCont,
  TestContainer,
  InputContainer,
} from "./styles";
import React, { useEffect, useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { useDispatch, useSelector } from "react-redux";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { useNavigate } from "react-router-dom";
import { ClearLead } from "../../../redux/actions/UpdateLead.actions";
import { rootReducer, RootState } from "../../../redux/reducers";
import { env } from './../../../env';

const QuestionEight = () => {
  const [selectedFile, setSelectedFile] = useState<any>();

  const [exit, setExit] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstName = useSelector(
    (rootReducer: RootState) => rootReducer.UpdateFinalObjReducer.lead.firstName
  );
  const finalObj = useSelector(
    (rootReducer: RootState) => rootReducer.UpdateFinalObjReducer
  );
  const claimObj = useSelector(
    (rootReducer: RootState) => rootReducer.claimReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log({ firstName });
  }, [firstName]);
  const onSubmit = async () => {
    Loading.standard("Loading...");

    let formData = new FormData();
    formData.append("uploadFile", selectedFile);
    formData.append("signature", claimObj.signature);
    formData.append(
      "",
      JSON.stringify({
        brokerUsed: claimObj.brokerUsed,
        finalObj,
      })
    );
    const url = env.REACT_APP_WEBHOOK_API || "";
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.send(
        formData
      );
      Loading.remove();
      dispatch(UpdateShowingQuestion(0));
      navigate("/thank-you");
    } catch (err) {
      Loading.remove();
      Notify.failure("An error occurred, please try again later.");
    }
  };

  const handleAttachmentButton = () => {
    document.getElementById("fileInput")!.click();
  };

  const onSelectFile = (e: any) => {
    const fileSizeLimit = 10242880;
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    } else if (e.target.files[0].size > fileSizeLimit) {
      Notify.info("Sorry, this file is a little too large.");
    } else {
      setSelectedFile(e.target.files[0]);
      console.log(e.target.files);
    }
  };

  useEffect(() => {
    console.log({ selectedFile });
  }, [selectedFile]);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
    }
  };

  // function dragOverHandler(ev: any) {
  //   console.log("File(s) in drop zone");

  //   // Prevent default behavior (Prevent file from being opened)
  //   ev.preventDefault();
  // }
  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <TestContainer>
          <Head>
            It looks like you could have a claim
            {firstName ? `, ${firstName}` : null}!
          </Head>
          <DetailsContainer>
            <p>
              In order to complete your claim, please upload your latest
              business energy bill below. This will also help to speed up your
              claim.
            </p>

            {selectedFile ? (
              <p className="fileName">File: {selectedFile.name}</p>
            ) : (
              <></>
            )}
            <InputContainer file={selectedFile}>
              {selectedFile ? null : (
                <p className="inputText">Drop your file here</p>
              )}
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                className="mainInput"
                accept="image/png, image/jpeg, .pdf"
                style={{ display: selectedFile ? "none" : "block" }}
                onChange={(e: any) => {
                  onSelectFile(e);
                }}
              />
            </InputContainer>

            <button
              className="fileUpload"
              onClick={() => handleAttachmentButton()}
            >
              {selectedFile ? "Upload Again" : "Upload File"}
            </button>
          </DetailsContainer>
          <NextCont>
            {selectedFile ? <Next onClick={onSubmit}>SUBMIT FILE</Next> : null}
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionEight;
