import {
  Container,
  DetailsContainer,
  Head,
  InnerContainer,
  Input,
  Label,
  LabelCont,
  Next,
  NextCont,
  TestContainer,
} from "./styles";
import React, { useEffect, useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { useDispatch } from "react-redux";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { UpdateFinalObj } from "redux/actions/UpdateFinalObj.actions";

const QuestionSix = () => {
  const [bad, setBad] = useState(false);
  const [number, setNumber] = useState("");
  const [exit, setExit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = async (e: any) => {
    const { value } = e.target;

    let tester = true;

    tester = /^[0-9+]+$/.test(value);

    if (tester || value === "")
      setNumber(value.toString());
  };

  const onClickNext = async () => {
    Loading.standard("Loading...");
    
    if(number.length > 0){
      dispatch(UpdateFinalObj(number, "lead", "phoneNumber")).then(() => {
        Loading.remove();
        dispatch(UpdateShowingQuestion(6));
      });
    }else{
      Loading.remove();
      Notify.failure("Please make sure your phone number is correct");
    }
    
  };

  return (
    <Container>
      <InnerContainer className={exit ? "exit-form" : ""}>
        <TestContainer>
          <Head>Please enter your phone number</Head>
          <DetailsContainer>
            <LabelCont>
              <Label>Your Phone Number</Label>
              <Input
                className={bad ? "bad" : "default"}
                onChange={onChange}
                value={number}
                type="text"
              ></Input>
            </LabelCont>
          </DetailsContainer>
          <NextCont>
            <Next onClick={onClickNext}>NEXT</Next>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionSix;
