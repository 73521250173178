import styled from "styled-components";

export const Container = styled.div`
  background: white;
  padding: 60px 0 60px 0;

  > div > div {
    margin-block: 25px;
  }
  @media (max-width: 1024px) {
    > div > div {
      flex-wrap: wrap;
      gap: 40px;
    }
  }
  @media (max-width: 800px) {
    > div {
      align-items: center;
    }
  }
  @media (max-width: 500px) {
    padding-bottom: 37px;
    img {
      max-height: 26px;
    }
  }
  @media (max-width: 1000px) and (min-width: 500px) {
    padding: 60px 30px 60px 30px;
  }
`;

export const Header = styled.h2`
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  color: #1e2241;
  margin-bottom: 62px;

  span {
    color: #269cd4;
  }
  @media (max-width: 500px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    margin: 0 24px 27px;
    width: 327px;
  }
`;

export const Image = styled.img`
  margin: 40px;
  @media (max-width: 500px) {
    width: 25%;
    margin: 12px;
    margin-bottom: 30px;
  }
  @media (max-width: 1000px) and (min-width: 500px) {
    width: 20%;
  }
`;

export const SubHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1e2241;
  margin-top: 66px;
  margin-bottom: 52px;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 26px;
    width: 327px;
    margin: 20px auto 21px;
  }
`;

export const SubSpan = styled.span`
  font-weight: 700;
`;

export const Button = styled.button`
  background: #e46f1a;
  border: 1px solid #ffffff;
  box-shadow: 6px 2px 14px rgba(0, 0, 0, 0.2);
  border-radius: 56px;
  padding: 16px 19px 16px 40px;
  display: flex;
  margin: auto;
  align-items: center;
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 12px 19px 12px 24px;
  }

  :hover {
    background: #c25708;
  }
  p {
    color: white;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    /* identical to box height */
    margin-right: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    @media (max-width: 500px) {
      font-size: 16px;
      margin-right: 28px;
    }
  }
`;
