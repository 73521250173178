import {
  leadObj,
  UPDATING_LEAD,
  updateLead,
  UpdatingLeadT,
} from "../types/UpdateLead.types";

const initialLeadState: leadObj = {
  phone: "",
  leadId: 0,
  reengage: false,
};

export const UpdateLeadReducer = (
  state: leadObj = initialLeadState,
  action: UpdatingLeadT
): leadObj => {
  switch (action.type) {
    case UPDATING_LEAD: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
