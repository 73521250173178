import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 121px;
  background-color: #f7f9fb;
`;

export const InnerContainer = styled.div`
  padding: 31px 0;
  display: flex;
  max-width: 1300px;
  margin-left: 91px;
  margin-right: 112px;

  @media (max-width: 500px) {
    padding: 0px 0px 0 0px;
  }
  @media (min-width: 1500px) {
    margin: auto;
    padding: 23px 0;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    margin: 0px 24px;
    padding-top: 23px;
  }
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #1e2241;
  width: 22%;
  margin-right: 87px;
  @media (max-width: 1000px) {
    width: 327px;
    font-size: 16px;
    line-height: 26px;
    margin-right: 0px;
    margin-bottom: 23px;
  }
  @media (max-width: 1000px) and (min-width: 500px) {
    width: fit-content;
    margin: auto;
    margin-bottom: 40px;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 78%;

  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 1000px) and (min-width: 500px) {
    width: 80%;
    margin: auto;
  }
`;

interface props {
  prop: string;
}

export const Image = styled.img<props>`
  margin-bottom: 30px;
  background: #f7f9fb;
  @media (max-width: 500px) {
    width: ${({ prop }: props) =>
      (prop === "1" && "165px") ||
      (prop === "2" && "107px") ||
      (prop === "3" && "186px") ||
      (prop === "4" && "99px")};
  }
  @media (max-width: 1000px) and (min-width: 500px) {
    width: ${({ prop }: props) =>
      (prop === "1" && "40%") ||
      (prop === "2" && "40%") ||
      (prop === "3" && "50%") ||
      (prop === "4" && "35%")};
  }
  @media (min-width: 1500px) {
    transform: scale(0.8);
  }
`;
