import React from "react";
import { img } from "../../assets/imageFolder";
import { Container, Inner, Row } from "./styles";

const Footer = () => {
  return (
    <Container>
      <Inner>
        <Row justify="space-between">
          <a href="/">
            <img src={img.whiteLogo} className="logo-white" alt="Your Business Energy Refund" />
          </a>
          <img src={img.securefooter} alt="Secure SSL Encryption" />
        </Row>
        <Row justify="center">
          <p>
            Broker Energy Claims is trading names of Pearson Locke Ltd which is
            a limited company registered in England & Wales with registration
            number 12851926. A list of directors of the company is available for
            inspection at the registered office: 86 Deansgate, Suite 1, First
            Floor, Manchester, M3 2ER. Authorised by the Solicitors Regulation
            Authority under Registration Number 807786. VAT number 384131407
          </p>
        </Row>
      </Inner>
    </Container>
  );
};

export default Footer;
