import styled from "styled-components";

export const Container = styled.footer`
  background: #101a5a;
  padding: 20px 80px 25px;
  margin-top: auto;
  @media (max-width: 500px) {
    padding: 0px 0px 0px;
  }
`;
export const Inner = styled.div`
  max-width: 1349px;
  margin: 0 auto;

  @media (max-width: 500px) {
    padding: 25px 9px 25px;
    transform: scale(0.9);
  }
  div:nth-child(2) {
    margin-top: 38px;
    margin-bottom: 75px;

    @media (max-width: 580px) {
      margin-top: 44px;
      margin-bottom: 40px;
      p {
        width: 90%;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
`;

interface iRow {
  justify: string;
}

export const Row = styled.div<iRow>`
  display: flex;
  justify-content: ${(props: iRow) => props.justify};
  align-items: center;

  section {
    display: flex;
    align-items: center;
    padding-block: 20px;

    a {
      margin-right: 38px;
    }
    a:link,
    a:visited {
      color: #ffffff;
      text-decoration: underline;
      cursor: pointer;
    }

    a:link:active,
    a:visited:active {
      color: #ffffff;
    }
  }

  .logo-white {
    width: 100px;
    height: 100px;  
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    max-width: 822px;

    @media (max-width: 580px) {
      font-size: 12px;
      text-align: center;
      line-height: 22px;
    }
  }
`;
