import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { img } from "../../assets/imageFolder";
import { UpdateShowingQuestion } from "../../redux/actions/ShowingQuestion.actions";
import {
  UpdateFinalObj,
  updateAddress,
} from "../../redux/actions/UpdateFinalObj.actions";
import useWindowSize, { Size } from "../../utilities/Hooks/useWindowSize";
import getBusinesses from "../../utilities/get-businesses/getBusinesses";
import getBusinessFilingDetails from "../../utilities/getBusinessFilingData/getBusinessFilingData";
import { DropContainer, MainContainer } from "../Questions/QuestionOne/styles";
import {
  Business,
  BusinessList,
  BusinessListContainer,
  Input,
  SearchButton,
  SearchCont,
  SearchContainer,
  SearchImg,
  SearchText,
  SubmitButton,
} from "./styles";

interface test {
  selectedBusiness: any;
  setSelectedBusiness: any;
  showObj: any;
  setShowObj: any;
}

const Search: React.FC<test> = ({
  selectedBusiness,
  setSelectedBusiness,
  showObj,
  setShowObj,
}) => {
  const [business, setBusiness] = useState("");
  const [busArr, setBusArr] = useState<any[]>([]);
  const [busNum, setBusNum] = useState("");
  const [fullBus, setFullBus] = useState<any>({});
  const [bad, setBad] = useState(false);
  const [correctStatus, setCorrectStatus] = useState(false);

  const size: Size = useWindowSize();
  const dispatch = useDispatch();

  const onKeyStroke = (e: any) => {
    setBusiness(e.target.value);
  };

  const onClick = () => {
    if (business !== "") {
      Loading.standard("Loading...");

      getBusinesses(business)
        .then((res: any) => {
          const fullArr: any = [];
          res.items.map((bus: any) => {
            fullArr.push(bus);
          });

          setBusArr(fullArr);
          console.log("fullArr", fullArr);

          Loading.remove();

          if (fullArr.length === 0) {
            Notify.failure("Sorry, No businesses found with that name");
          } else {
            setShowObj({
              popup: true,
              businesses: true,
              submit: false,
              search: false,
            });
          }
        })
        .catch((err) => {
          Notify.failure("Sorry, there was an error fetching data");
          setShowObj({
            popup: true,
            businesses: false,
            submit: false,
            search: false,
          });
        });
    } else {
      setBad(true);
      Notify.failure("Please enter a business name");
    }
  };

  const listClick = (busObj: any) => {
    setSelectedBusiness(busObj.title);
    setFullBus(busObj);
    setBusNum(busObj.company_number);
    setShowObj({
      popup: true,
      businesses: false,
      submit: true,
      search: true,
    });
    setBusiness(busObj.title);
    if (busObj.company_status === "active") {
      setCorrectStatus(true);
    } else {
      setCorrectStatus(false);
    }
  };

  const submitClick = () => {
    if (Object.values(fullBus).length > 0 && correctStatus) {
      setBusiness(selectedBusiness);
      setShowObj({
        popup: false,
        businesses: false,
        submit: false,
        search: false,
      });

      dispatch(UpdateFinalObj(business, "lead", "businessName"));
      dispatch(UpdateFinalObj(busNum, "company", "companyNumber"));
      dispatch(UpdateFinalObj(fullBus, "company", "infoDump"));
      dispatch(
        UpdateFinalObj(fullBus.companyStatus, "company", "companyStatus")
      );
      dispatch(UpdateFinalObj(fullBus.company_type, "company", "companyType"));
      dispatch(
        UpdateFinalObj(fullBus.date_of_creation, "company", "dateOfCreation")
      );
      dispatch(UpdateFinalObj(fullBus.description, "company", "description"));

      dispatch(updateAddress(fullBus.address_snippet));

      getBusinessFilingDetails(busNum).then((res) => {
        dispatch(UpdateFinalObj(res, "company", "lastFiling"));
      });

      dispatch(UpdateShowingQuestion(1));
    } else if (!correctStatus) {
      Notify.failure("Sorry, only active companies can proceed.");
    } else {
      Notify.failure("Please select a business");
    }
  };

  return (
    <MainContainer>
      <SearchContainer>
        <DropContainer>
          <Input
            type="text"
            placeholder="Enter company name..."
            onChange={onKeyStroke}
            value={business}
            className={bad ? "bad" : "default"}
          />
          <BusinessListContainer
            className={showObj.businesses ? "" : "hidden"}
            open={showObj.businesses}
          >
            <BusinessList>
              {busArr.map((bus) => {
                return (
                  <Business onClick={() => listClick(bus)}>
                    {bus.title}
                  </Business>
                );
              })}
            </BusinessList>
          </BusinessListContainer>
        </DropContainer>
        <SearchButton
          className={!showObj.search && size.width! < 750 ? "hideButton" : ""}
          onClick={onClick}
        >
          <SearchImg src={img.magnifyingGlass} alt="search button icon" />

          <SearchText>SEARCH</SearchText>
        </SearchButton>
      </SearchContainer>
      <SearchCont>
        <SubmitButton
          className={showObj.submit ? "" : "hideButton"}
          onClick={submitClick}
        >
          NEXT
        </SubmitButton>
      </SearchCont>
    </MainContainer>
  );
};

export default Search;
