import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);
  min-height: 100vh;
  @media (min-width: 1900px) {
    padding-top: 5%;
  }
`;

export const InnerContainer = styled.div``;

export const DropdownContainer = styled.div`
  @media (max-width: 500px) {
    padding-bottom: 120px;
  }

  padding-bottom: 120px;
`;

export const Head = styled.h1`
  text-align: center;
  padding-top: 74px;
  margin-top: 0px;
  margin-bottom: 62px;
  font-weight: 700;
  font-size: 38px;
  color: white;
  /* 
  @media (max-width: 500px) {
    font-size: 25px;
    margin: 75px 19px 52px 19px;
    padding: 0;
  } */
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
    width: 327px;
    margin: auto;
    padding-top: 38px;
    padding-bottom: 40px;
  }
  @media (min-height: 1080px) {
    font-size: 40px;
    max-width: 774px;

    margin: auto;
    margin-bottom: 62px;

    /* padding-top: 80px; */
  }
`;

export const MainContainer = styled.div`
  min-height: 200px;

  @media (max-width: 500px) {
    margin-bottom: 0px;
  }
`;

export const DropContainer = styled.div`
  position: relative;
`;

export const DevButton = styled.button``;
