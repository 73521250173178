import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { UpdateShowingQuestion } from "../../../redux/actions/ShowingQuestion.actions";
import { UpdateFinalObj } from "../../../redux/actions/UpdateFinalObj.actions";
import { ClearLead } from "../../../redux/actions/UpdateLead.actions";
import { updateClaim } from "../../../redux/actions/claim.actions";
import {
  Container,
  Head,
  InnerContainer,
  Next,
  NextCont,
  TestContainer,
} from "../QuestionSix/styles";
import { Consent, SignatureContainer } from "./styles";

import { store } from "../../../redux/store";

const QuestionSeven = () => {
  const [showImg, setShowImg] = useState(true);
  const [consent, setConsent] = useState(false);

  const dispatch = useDispatch();

  const signatureRef = useRef(null) as MutableRefObject<any>;
  const claim = store.getState().claimReducer;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(claim.signature);
  }, [claim]);

  const onSubmit = async () => {
    if (!consent) {
      Notify.failure("Please agree to TCs and Privacy Policy to continue.");
    } else if (showImg) {
      Notify.failure("Please make sure you have signed.");
    } else {
      Loading.standard("Loading...");
      Loading.remove();
      dispatch(UpdateShowingQuestion(7));
    }
  };

  const saveSign = () => {
    signatureRef.current.on();
    let sig = signatureRef.current.toDataURL();
    signatureRef.current.on();
    const padding = 10;
    const trimmedCanvas = signatureRef.current.getTrimmedCanvas();
    const paddedCanvas = document.createElement("canvas");
    paddedCanvas.width = trimmedCanvas.width + 2 * padding;
    paddedCanvas.height = trimmedCanvas.height + 2 * padding;
    const context = paddedCanvas.getContext("2d")!;
    context.fillStyle = "white"; // Set the padding color
    context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height); // Fill the entire canvas with padding color
    context.drawImage(trimmedCanvas, padding, padding);
    sig = paddedCanvas.toDataURL();

    const head = "data:image/png;base64,";
    const sigFileSize = Math.round(((sig.length - head.length) * 3) / 4);
    const kbLimit = 75000; // 75mb, under the DB limit
    console.log({ sigFileSize });
    console.log(sig, "sig");

    sigFileSize < kbLimit
      ? dispatch(updateClaim("signature", sig))
      : Notify.failure(
          "Your signature looks like it's too large, please redo slightly smaller."
        );
  };

  const clearSign = () => {
    signatureRef.current.on();
    signatureRef.current.clear();
    dispatch(updateClaim("signature", ""));
    setShowImg(!showImg);
  };

  return (
    <Container>
      <InnerContainer>
        <TestContainer>
          <Head>We just need you to sign here:</Head>

          <SignatureContainer showImg={showImg}>
            <span>
              {showImg ? (
                <h3>
                  DRAW SIGNATURE WITH MOUSE ON DESKTOP / FINGER ON MOBILE OR
                  TABLET
                </h3>
              ) : (
                <h3 onClick={() => clearSign()}>Clear</h3>
              )}
            </span>

            <SignatureCanvas
              ref={signatureRef}
              onEnd={saveSign}
              onBegin={() => setShowImg(false)}
              clearOnResize={false}
              penColor="black"
              canvasProps={{
                width: window.innerWidth < 768 ? 327 : 758,
                height: window.innerWidth < 768 ? 182 : 202,
                className: "sigCanvas",
              }}
              data-cy="canvas"
            />

            <Consent>
              <input type="checkbox" onChange={() => setConsent(!consent)} />

              <p>
                I have read and agreed to the terms in the attached Retainer
              </p>
            </Consent>
          </SignatureContainer>

          <NextCont>
            <Next onClick={() => onSubmit()}>NEXT</Next>
          </NextCont>
        </TestContainer>
      </InnerContainer>
    </Container>
  );
};

export default QuestionSeven;
