import { ActionCreator, Dispatch } from "redux";
import { iClaimObj, UPDATING_CLAIM, ClaimT } from "../types/claim.types";

export const claimAction: ActionCreator<ClaimT> = (payload: iClaimObj) => {
  return { type: UPDATING_CLAIM, payload: payload };
};

export const updateClaim = (key: string, value: boolean | string): any => {
  return async (dispatch: Dispatch, getState: any) => {
    let claim = getState()?.claimReducer;

    const updatedDetails = {
      ...claim,
      [key]: value,
    };

    console.log({ updatedDetails });

    dispatch(claimAction(updatedDetails));
  };
};
