import styled, { keyframes } from "styled-components";

const glideIn = keyframes`
  0%{
    opacity:0%
  }
  100%{
    opacity:100%;
  transform: translateY(0%);

  }
`;

export const Container = styled.div`
  background: linear-gradient(90deg, #1c1f33 0%, #090537 0.01%, #034787 100%);
  min-height: 100vh;

  @media (min-height: 1080px) {
    /* margin-top: -7%; */
  }

  @media (min-width: 1900px) {
    padding-top: 5%;
    padding-bottom: 16%;
  }
`;

export const TestContainer = styled.div`
  animation: ${glideIn} 0.3s linear 0s 1 forwards;
  opacity: 0%;
  transform: translateY(30%);
`;

export const InnerContainer = styled.div``;

export const Head = styled.h1`
  text-align: center;
  padding-top: 74px;

  font-weight: 700;
  font-size: 38px;
  color: white;
  margin-bottom: 48px;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
    width: 327px;
    margin: auto;
    padding-top: 38px;
    padding-bottom: 00px;
  }

  @media (max-width: 1000px) and (min-width: 500px) {
    margin: 0px 25px 52px 25px;
  }

  @media (min-height: 1080px) {
    font-size: 40px;
    max-width: 774px;

    margin: auto;
    margin-bottom: 62px;
  }
`;

export const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
`;

export const LabelCont = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 500px) {
  }
`;

export const Label = styled.label`
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  color: white;
  @media (min-height: 1080px) {
    /* width: 700px; */

    font-size: 20px;
  }
`;

export const Input = styled.input`
  width: 409px;
  height: 52px;
  font-family: "Inter", sans-serif;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  color: white;
  font-size: 16px;
  font-weight: 300;
  padding-left: 20px;
  border-radius: 1px;

  @media (max-width: 500px) {
    width: 327px;
    max-width: 95vw;
    height: 46px;
  }
  @media (min-height: 1080px) and (min-width: 1050px) {
    width: 500px;
    height: 60px;
    font-size: 35px;
  }
`;

export const Next = styled.button`
  width: fit-content;

  background: #e46f1a;
  border: 1px solid #ffffff;
  box-shadow: 6px 2px 14px rgba(0, 0, 0, 0.2);
  border-radius: 56px;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;

  padding: 16px 56px;

  /* margin-top: 69px; */
  @media (max-width: 500px) {
    width: 155px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
  }

  @media (min-height: 1080px) {
    width: 300px;
    height: 80px;
    font-size: 35px;
    margin-top: 20px;
  }

  :hover {
    cursor: pointer;
    background: #c25708;
  }
`;

export const NextCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 17px;
  /* margin-bottom: 98px; */
  margin-top: 40px;

  padding-bottom: 98px;

  @media (max-width: 500px) {
    margin-top: 66px;
  }
`;

export const Legal = styled.p`
  font-weight: 300;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  text-align: center;
  margin-top: 46px;
  @media (max-width: 500px) {
    width: 80%;
    margin: 46px auto;
  }
  @media (min-height: 1080px) {
    font-size: 20px;
  }
`;
