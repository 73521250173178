export interface stateObj {
  one: boolean;
  two: boolean;
  three: boolean;
  four: boolean;
  five: boolean;
  six: boolean;
  thank: boolean;
}

export const UPDATING_QUESTIONS = "UPDATING_QUESTIONS";

export interface updateQuestion {
  type: typeof UPDATING_QUESTIONS;
  payload: stateObj;
}
export type UpdatingQuestionT = updateQuestion;
