import { img } from "../../assets/imageFolder";

export interface iTestimonialObj {
  quote: string;
  img: string;
  name: string;
}
export const testimonialData: iTestimonialObj[] = [
  {
    quote:
      "The cost of running my company surged during lockdown, but thanks to Refund My Business Energy, I found out I was being overcharged and got money back in a matter of weeks!",
    img: img.person1,
    name: "Bill O, Salford",
  },
  {
    quote:
      "I’d long suspected I was being charged too much for my energy, and thanks to the Refund My Business Energy team, they helped me find out just how much extra I was unnecessarily paying.",
    img: img.person2,
    name: "David A, Huddersfield",
  },
  {
    quote:
      "I was able to claim back hundreds of pounds, as it turned out the broker I used was pocketing a large chunk of the money I was paying every month on my deal.",
    img: img.person3,
    name: "Jack S, Newcastle",
  },
];
