import React from "react";
import { Container, Header, SubHeader, SubSpan } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { provider } from "../../assets/imageFolder/providers";
import Button from "../../components/Button";
import { Column, Row } from "../../theme/reuseable";
import useWindowSize from "../../utilities/Hooks/useWindowSize";

const Providers = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <Container>
      <Header>
        Have You Had A <span>Business Energy Deal </span>With These Providers?
      </Header>
      <Column align="center">
        {width! > 800 ? (
          <>
            <Row gap="106px" justify-content="center">
              <img src={provider.bg} alt="British Gas" />
              <img src={provider.sse} alt="SSE" />
              <img src={provider.crown} alt="Crown Gas Power" />
              <img src={provider.eon} alt="E-On" />
              <img src={provider.ovo} alt="Ovo Energy" />
            </Row>
            <Row gap="40px" justify-content="center">
              <img src={provider.nPower} alt="nPower" />
              <img src={provider.coronaEnergy} alt="Corona Energy" />
              <img src={provider.smartestEnergy} alt="Smartest Energy" />
              <img src={provider.pozitiveEnergy} alt="Poizitive Energy" />
              <img src={provider.total} alt="Total" />
              <img src={provider.engie} alt="Engie" />
              <img src={provider.gazprom} alt="Gazprom" />
            </Row>
            <Row gap="107px" justify-content="center">
              <img src={provider.scot} alt="Scottish Power" />
              <img src={provider.opus} alt="Opus Energy" />
              <img src={provider.drax} alt="Drax" />
              <img src={provider.edf} alt="EDF" />
              <img src={provider.octopusEnergy} alt="Octopus Energy" />
            </Row>
          </>
        ) : (
          <>
            <Row gap="40px" justify-content="center">
              <img src={provider.bg} alt="British Gas" />
              <img src={provider.sse} alt="SSE" />
              <img src={provider.crown} alt="Crown Gas Power" />
            </Row>
            <Row gap="40px" justify-content="center">
              <img src={provider.eon} alt="E-On" />
              <img src={provider.ovo} alt="Ovo Energy" />
              <img src={provider.nPower} alt="nPower" />
            </Row>
            <Row gap="40px" justify-content="center">
              <img src={provider.coronaEnergy} alt="Corona Energy" />
              <img src={provider.smartestEnergy} alt="Smartest Energy" />
              <img src={provider.pozitiveEnergy} alt="Poizitive Energy" />
            </Row>

            <Row gap="40px" justify-content="center">
              <img src={provider.total} alt="Total" />
              <img src={provider.engie} alt="Engie" />
              <img src={provider.gazprom} alt="Gazprom" />
            </Row>
            <Row gap="40px" justify-content="center">
              <img src={provider.scot} alt="Scottish Power" />
              <img src={provider.opus} alt="Opus Energy" />
              <img src={provider.drax} alt="Drax" />
            </Row>
            <Row gap="40px" justify-content="center">
              <img src={provider.edf} alt="EDF" />
              <img src={provider.octopusEnergy} alt="Octopus Energy" />
            </Row>
          </>
        )}
      </Column>
      <SubHeader>
        You Could Have Been <SubSpan>Mis-Sold Your Deal</SubSpan> Without
        Knowing...
      </SubHeader>

      <Button
        btnText="CHECK FOR FREE"
        btnImg
        onClick={() => navigate("/form")}
      />
    </Container>
  );
};

export default Providers;
