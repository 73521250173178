export const LendersList: string[] = [
  "Avro Energy",
  "Better Energy",
  "Boost Power",
  "British Gas",
  "Bulb Energy",
  "Co-operative Energy",
  "E.ON",
  "EDF Energy",
  "Engie",
  "Energy Plus",
  "Enstroga",
  "Entice Energy",
  "ESB Energy",
  "Extra Energy",
  "Flow Energy",
  "Foxglove Energy",
  "Future Energy",
  "GB Energy Supply",
  "Good Energy",
  "Great North Energy",
  "Green Energy UK",
  "Green Network Energy",
  "Igloo Energy",
  "Lumo Energy",
  "M&S Energy",
  "Nabuh Energy",
  "npower",
  "Octopus Energy",
  "OVO Energy",
  "People's Energy",
  "Powershop UK",
  "Pure Planet",
  "Robin Hood Energy",
  "Scottish Power",
  "Shell Energy",
  "So Energy",
  "SSE",
  "Tonik Energy",
  "Utilita Energy",
  "Utility Point",
  "Utility Warehouse",
  "Yorkshire Energy",
];
