import styled from "styled-components";

export const Container = styled.div`
  padding-top: 67px;
  max-width: 100vw;
  background: #f7f9fb;
  padding-bottom: 50px;
  @media (max-width: 1020px) {
    padding: 43px 24px;
    /* width: 327px; */
  }
`;

export const Header = styled.h2`
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 44px;
  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 26px;
  }
`;

export const FaqsContainer = styled.div`
  width: 971px;
  margin: auto;
  margin-bottom: 100px;
  transition: 0.4s;
  @media (max-width: 500px) {
    width: 327px;
  }
  @media (max-width: 1020px) and (min-width: 500px) {
    width: 100%;
  }
`;
