import styled, { keyframes } from "styled-components";
import { img } from "../../../assets/imageFolder/index";

interface iSignatureContainer {
  showImg: boolean;
}
export const SignatureContainer = styled.div<iSignatureContainer>`
  width: 758px;
  margin: auto;
  margin-top: 63px;

  span {
    background: #00a6f5;
    display: flex;
    align-items: center;
    width: 758px;
    border-radius: 5px 5px 0 0;

    h3 {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      padding-block: 9px;
      margin: 0 auto;
    }
  }

  .sigCanvas {
    background: white;
    border-radius: 0 0 5px 5px;

    ${({ showImg }: iSignatureContainer) =>
      showImg
        ? `
    background-image: url(${img.signatureImg});
    background-size: 45% 45%;
    background-position: center center;
    z-index: 2;
    background-repeat: no-repeat;
    `
        : ""}
  }
  @media (max-width: 768px) {
    width: 327px;
    span {
      width: 327px;
      h3 {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }
    }

    .sigCanvas {
      background-size: 95% 60%;
    }
  }
`;

export const Consent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  input {
    margin-right: 8px;
    height: 25px;
    width: 25px;
    accent-color: rgb(30, 34, 159);
  }
  p,
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: white;
  }

  a {
    color: rgba(0, 166, 245, 1);
  }
  @media (max-width: 768px) {
    p,
    a {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    }
  }
`;
