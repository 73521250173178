import styled, { keyframes } from "styled-components";
import { isStringLiteral } from "typescript";
import { theme } from "./theme";

export interface iSCOpen {
  open: boolean;
}

export interface iSCColumn {
  align: string;
}

export const Inner = styled.section`
  max-width: ${theme?.content?.maxWidth};
  margin: auto;
  position: relative;
`;

export const CenteredRowMixin = `
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColumnMixin = `
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div<iSCColumn>`
  display: flex;
  flex-direction: column;
  ${(props: iSCColumn) => props.align && `align-items: ${props.align}`}
`;

interface iRow {
  gap?: string;
  "justify-content"?: string;
}

export const Row = styled.div<iRow>`
  ${CenteredRowMixin};

  ${(props: iRow) => props.gap && `gap: ${props.gap}`}
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
  `;

export const ResponsiveColumnToRow = styled.div`
  ${ColumnMixin}

  @media (min-width: 1024px) {
    ${CenteredRowMixin}
    gap: 16px;

    input {
      width: 261px !important;
    }
  }
`;
