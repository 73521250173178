import styled from "styled-components";

export const Container = styled.div`
  background: #f7f9fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 102px;

  max-width: 1500px;
  margin: auto;
  padding: 0px 73px;

  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 0px 24px 10px;
    height: unset;
    justify-content: center;
  }

  @media (max-width: 1400px) and (min-width: 1300px) {
    padding: 0px 40px;
  }
  @media (max-width: 1300px) and (min-width: 1200px) {
    padding: 0px 20px;
  }

  @media (max-width: 700px) {
    align-items: flex-start;
  }
`;
export const OuterContainer = styled.div`
  background: #f7f9fb;
`;

export const Guarantee = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  img {
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    @media (max-width: 1270px) and (min-width: 1100px) {
      font-size: 12px;
    }
    span {
      font-weight: 700;
    }
  }

  @media (min-height: 700px) {
    margin-left: 0 !important;
  }
`;

export const BlackLine = styled.img`
  position: absolute;
  top: 35px;
  left: 27px;
  @media (max-width: 1270px) and (min-width: 1100px) {
    left: 28px;
    width: 11%;
  }
`;
